import React, { Component } from "react";
import { Modal, Button } from 'react-bootstrap';
import BuscaCep from '../../../Components/BuscaCep.js';
import APIHaut from '../../../http/hautDigital.js';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});


export class AdicionarEmpreendimento extends Component {
    constructor(props) {
        super(props);
        this.salvarAlteracao = this.salvarAlteracao.bind(this);
        this.getValue = this.getValue.bind(this);
        this.setNomeEmpreendimento = this.setNomeEmpreendimento.bind(this);
        this.setvalorUnidade = this.setvalorUnidade.bind(this);
        this.setCustoUnidade = this.setCustoUnidade.bind(this);
        this.pushUnidadeRequest = this.pushUnidadeRequest.bind(this);

        this.state = {
            endereco: {},
            nomeEmpreendimento: "",
            valorUnidade: "",
            contador: 0,
            custoUnidade: 0
        }

    }

    pushUnidadeRequest(empreendimento) {
        APIHaut.post('/unidades/push', {
            id_empreendimento: empreendimento.id
        })
            .then(res2 => {
                if (res2.status === 201) {
                    this.setState({ contador: this.state.contador + 1 }, () => {
                        document.querySelector('#jafoi-qtd').innerHTML = this.state.contador
                        document.querySelector('#faltam-qtd').innerHTML = empreendimento.qtdUnidades - this.state.contador
                        this.pushUnidadeRequest(empreendimento);
                    });
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: 'success',
                    title: 'Prontinho, unidades criadas!',
                    html: 'Esta mensagem vai se fechar automaticamente em 1 segundo',
                    timer: 1500
                });
            });
    }

    getValue(value) {
        this.setState({
            ...this.state, endereco: value
        })
    }

    salvarAlteracao() {
        APIHaut.post("/empreendimentos", {
            nome: this.state.nomeEmpreendimento,
            qtdUnidades: this.state.valorUnidade,
            custoUnidade: this.state.custoUnidade,
            endereco: {
                logradouro: this.state.endereco.rua,
                cep: this.state.endereco.cep,
                bairro: this.state.endereco.bairro,
                numero: this.state.endereco.numero,
                cidade: this.state.endereco.cidade,
                uf: this.state.endereco.estado
            }
        }).then(res => {
            if (res.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Empreendimento criado!',
                    timer: 1500,
                    showConfirmButton: false
                }).then(() => {
                    Swal.fire({
                        icon: 'question',
                        title: 'Criar unidades agora?',
                        html: 'As unidades são criadas automaticamente e pode levar algum tempo dependendo da quantidade de unidades do empreendimento<div class="mt-2"><strong>Você pode cancelar a qualquer momento</strong></div>',
                        showCancelButton: true,
                        confirmButtonColor: '#28a745',
                        confirmButtonText: 'Sim',
                        cancelButtonColor: '#dc3545',
                        cancelButtonText: 'Não'
                    })
                        .then(ok => {
                            if (ok.value) {
                                Swal.fire({
                                    title: 'Criando unidades',
                                    footer: `Já foi <span class="ml-1" id="jafoi-qtd">${this.state.contador}</span>, faltam <span class="ml-1" id="faltam-qtd">${res.data.qtdUnidades - this.state.contador}</span>`,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: false,
                                    onOpen: function () {
                                        Swal.showLoading();
                                    }
                                });

                                this.pushUnidadeRequest(res.data);
                            }
                        });
                });
            }
        }).catch(err => {
            Toast.fire({
                icon: 'error',
                title: 'Não foi possível concluir o cadastro.'
            });
        });
    }

    setNomeEmpreendimento(val) {
        this.setState({ ...this.state, nomeEmpreendimento: val })
    }

    setvalorUnidade(val) {
        this.setState({ ...this.state, valorUnidade: val })
    }

    setCustoUnidade(val) {
        this.setState({ custoUnidade: val });
    }

    render() {
        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                id="empreendimentosEditar"
            // centere
            >
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar empreendimento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>Nome do empreendimento</label>
                        <input className="form-control" type="text" placeholder="Nome" onChange={e => { this.setNomeEmpreendimento(e.target.value) }} />
                    </div>
                    <div className="form-group">
                        <label>Quantidade de unidades</label>
                        <input className="form-control" type="number" placeholder="Quantidade" onChange={e => { this.setvalorUnidade(e.target.value) }} />
                    </div>
                    <div className="form-group">
                        <label>Custo da Unidade</label>
                        <input className="form-control" type="number" placeholder="Custo da Unidade" onChange={e => { this.setCustoUnidade(e.target.value) }} />
                    </div>
                    <BuscaCep getValue={this.getValue} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='dark rounded' onClick={this.salvarAlteracao}> Salvar alterações</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
