import React, { Component } from "react";
import { Modal, Button} from 'react-bootstrap';
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class ModalConfirmar extends Component {
    constructor(props) {
        super(props);

        this.onConfirm = this.onConfirm.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onConfirm() {
        this.props.onConfirm();
    }

    onCancel() {
        this.props.onCancel();
    }

    render() {
        return ( 
            <Modal
                show={this.props.show}
                onHide={this.onCancel}
                size = "md"
                aria-labelledby = "contained-modal-title-vcenter"
                id="empreendimentosEditar"
                centered
            >
            <Modal.Body className="text-center">
            <FontAwesomeIcon  icon={ faExclamationCircle } style={{fontSize: "100px", color: "#F8CF86"}} className="util-margin-bottom1"/><br></br>
                Você tem certeza que deseja deletar esse empreendimento e todos os dados vinculados a ele?                
            </Modal.Body> 
            <Modal.Footer className="justify-content-center">
                <Button variant="secondary" className="w-25" onClick={this.onConfirm}>Sim</Button>
                <Button variant="primary" className="w-50" onClick={this.onCancel}>Não</Button>
            </Modal.Footer>
            </Modal>
        )
    }
}
