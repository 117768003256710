import React from 'react';
import ApiCep from '../Services/ApiCep';
import "./BuscaCep.css";



class BuscarCep extends React.Component {  

  handleDados(e) {
    // Pegando o CEP
    const cep = e.target.value;
    // Consultando a API
    ApiCep.SearchCep(cep).then((res) => {
      if (res.status === 200) {
        this.props.onChange({
          ...res.data,
          cidade: res.data.localidade
        });
      }
    });
  }
  
  render() {
    return(
      <div>
          <form id="form-Cep">
                    <div className="row p-20 w-100">

                       <div className="col-md-2" >
                           CEP
                       </div>

                       <div className="col-md-2">
                           Lougadoro
                       </div>

                       <div className="col-md-2">
                           Número
                       </div>

                       <div className="col-md-2">
                           Bairro
                       </div>

                       <div className="col-md-2">
                           Cidade
                       </div>

                       <div className="col-md-2">
                           Estado
                       </div>

                       </div>  

                       <div className="row p-20 w-100">

                        <div className="col-md-2">
                          <input
                            type="text"
                            onBlur={ this.handleDados.bind(this) }
                            required={true}
                            placeholder="Cep"
                            disabled={this.props.onDisable}
                            value={this.props.endereco.cep}
                            onChange={e => this.props.cepHandle(e.target.value)}
                          /> 
                        </div>

                        <div className="col-md-2">
                          <input type="text" value={ this.props.endereco.logradouro } disabled/>  
                        </div>

                        <div className="col-md-2">
                          <input
                            type="number"
                            required={true}
                            placeholder="Número"
                            value={this.props.endereco.numero}
                            disabled={this.props.onDisable}
                            onChange={e => this.props.numeroHandle(e.target.value)}
                          />   
                        </div>

                        <div className="col-md-2">
                          <input type="text" value={ this.props.endereco.bairro } disabled/>   
                        </div>

                        <div className="col-md-2">
                          <input type="text" value={ this.props.endereco.cidade } disabled/>   
                        </div>

                        <div className="col-md-2">
                          <input type="text"  value={ this.props.endereco.uf } disabled/>   
                        </div>

                        </div>  
          </form>
      </div>
    );
  }
}

export default BuscarCep;