import axios from "axios";

// Produção
const baseUrl = "https://haut-digital.herokuapp.com/"
//
// Dev
// const baseUrl = "http://localhost:3000/"

export default axios.create({
    baseURL: baseUrl,
    timeout: 10000
});