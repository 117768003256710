import React, { Component } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default class BotaoEdit extends Component {    
    render() {          
        return (
            <div>
                <div className="myButton"><FontAwesomeIcon className="mr-3" icon={faCheck}/>Salvar</div>
                </div>

         )
    }
}