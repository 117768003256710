import React, { Component } from "react";
import { Modal, Button } from 'react-bootstrap';
import APIHaut from '../http/hautDigital.js';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})



export default class ModalInserir extends Component {
    constructor(props) {
        super(props);

        this.state = {
            empreendimentoArray: [],
            usuariosArray: [],
            clientesArray: [],
            unidades: [],
            corretorArray: [],
            embaixadorArray: []
        }

        this.atualizarUnidades = this.atualizarUnidades.bind(this);
    }
    salvarAlteracao(e) {
        if (document.getElementById('unidade') === null ||
            document.getElementById('precoUni') === null ||
            document.getElementById('precoTabela') === null ||
            document.getElementById('dataCompra') === null ||
            document.getElementById('valorInicial') === null ||
            document.getElementById('valorReal') === null ||
            document.getElementById('comissao') === null ||
            document.getElementById('valorPermuta') === null ||
            document.getElementById('valorRecebido') === null
        ) {
            Toast.fire({
                icon: 'error',
                title: 'Verifique se não há algum campo em branco.'
            })
        }
        try {
            APIHaut.post('/vendas', {
                precoVendido: document.getElementById('precoVendido').value,
                precoTabela: document.getElementById('precoTabela').value,
                dataCompra: document.getElementById('dataCompra').value,
                motivacao: document.getElementById('motivacao').value,
                isJantar: document.getElementById('isJantar').value,
                valorRecebidoPermuta: document.getElementById('valorRecebidoPermuta').value,
                valorRecebidoFinanceiro: document.getElementById('valorRecebidoFinanceiro').value,
                totalAReceber: document.getElementById('totalAReceber').value,
                totalAReceberPermuta: document.getElementById('totalAReceberPermuta').value,
                totalAReceberFinanceiro: document.getElementById('totalAReceberFinanceiro').value,
                totalRecebido: document.getElementById('totalRecebido').value,
                id_corretor: document.getElementById('corretorName').value,
                id_empreendimento: document.getElementById('empreendimentoName').value,
                id_unidade: document.getElementById('unidade').value,
                id_cliente: document.getElementById('clienteName').value,
                id_usuario: document.getElementById('usuarioName').value,
                canal: document.getElementById('canal').value,
                fluxo: document.getElementById('fluxo').value,
                decisaoCompra: document.getElementById('decisaoCompra').value,
                tipoPermuta: document.getElementById('tipoPermuta').value
            })
                .then(res => {
                    Toast.fire({
                        icon: 'success',
                        title: 'Venda cadastrado com sucesso.'
                    })
                    window.location.reload(true);
                }).catch(error => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Não foi possível cadastrar a venda.'
                    })
                })
        } catch (error) {
            console.log(error)
        }


    }
    componentDidMount() {
        APIHaut.get("/empreendimentos")
            .then(res => {
                res.data.map(empreendimento => {
                    this.setState({
                        empreendimentoArray: [
                            ...this.state.empreendimentoArray,
                            {
                                id: empreendimento.id,
                                nome: empreendimento.nome
                            }
                        ]
                    });
                    return null
                });
            });

        APIHaut.get("/usuarios")
            .then(res => {
                res.data.map(usuario => {
                    this.setState({
                        usuariosArray: [
                            ...this.state.usuariosArray,
                            {
                                id: usuario.id,
                                nome: usuario.data.nome
                            }
                        ]
                    });

                    return null;
                });
            });

        APIHaut.get("/corretores")
            .then(res => {
                res.data.map(corretor => {
                    this.setState({
                        corretorArray: [
                            ...this.state.corretorArray,
                            {
                                id: corretor.id,
                                nome: corretor.data.nome
                            }
                        ]
                    });

                    return null
                });
            });

        APIHaut.get("/clientes")
            .then(res => {
                res.data.map(clientes => {
                    this.setState({
                        clientesArray: [
                            ...this.state.clientesArray,
                            {
                                id: clientes.id,
                                nome: clientes.data.nome
                            }
                        ]
                    });
                    return null
                });
            });

        APIHaut.get("/vendas")
            .then(res => {
                var arrayCorrigido = [];
                res.data.map(venda => {
                    arrayCorrigido.push({
                        id: venda.id
                    });

                    return null
                });

                this.setState({ data: arrayCorrigido });
            }).catch(err => {
                Toast.fire({
                    icon: 'error',
                    title: 'Não conseguimos atualizar os dados no momento, por favor atualize a página ou contate a equipe de TI.'
                })
            })
    }

    atualizarUnidades(id) {
        APIHaut.get(`/unidades/empreendimentos/${id}`).then(res => {
            this.setState({ unidades: res.data });
        });
    }


    render() {
        const { empreendimentoArray } = this.state;
        const { corretorArray } = this.state;
        const { clientesArray } = this.state;
        const { usuariosArray } = this.state;

        let usuarioList = usuariosArray.length > 0
            && usuariosArray.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.nome}</option>
                )
            }, this);

        let empreendimentoList = empreendimentoArray.length > 0
            && empreendimentoArray.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.nome}</option>
                )
            }, this);

        let corretorList = corretorArray.length > 0
            && corretorArray.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.nome}</option>
                )
            }, this);

        let clientesList = clientesArray.length > 0
            && clientesArray.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.nome}</option>
                )
            }, this);

        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                id="modalInserir"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Inserir venda</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Embaixador</label>
                    <select className="form-control" id="usuarioName">
                        <option value="" disabled selected>Selecione o embaixador</option>
                        {usuarioList}
                    </select>
                    <label>Corretor</label>
                    <select className="form-control" id="corretorName">
                        <option value="" disabled selected>Selecione o corretor</option>
                        {corretorList}
                    </select>
                    <label>Cliente</label>
                    <select className="form-control" id="clienteName">
                        <option value="" disabled selected>Selecione o cliente</option>
                        {clientesList}
                    </select>
                    <label>Empreendimento</label>
                    <select className="form-control" id="empreendimentoName" onChange={e => this.atualizarUnidades(e.target.value)}>
                        <option value="" disabled selected>Selecione o Empreendimento</option>
                        {empreendimentoList}
                    </select>
                    <label>Unidade</label>
                    <select className="form-control" id="unidade">
                        <option value="" disabled selected>Selecione a Unidade</option>
                        {this.state.unidades.map(unidade => {
                            return <option value={unidade.id}>{unidade.numero}</option>
                        })}
                    </select>
                    <label>Preço vendido</label>
                    <input className="form-control" id="precoVendido" type="number" placeholder="Preço vendido" />
                    <label>Preço da tabela</label>
                    <input className="form-control" id="precoTabela" type="number" placeholder="Preço da tabela" />
                    <label>Data de compra</label>
                    <input className="form-control" id="dataCompra" type="date" placeholder="Data da compra" />
                    <label>Fluxo</label>
                    <input className="form-control" id="fluxo" type="text" placeholder="Fluxo" />
                    <label>Area</label>
                    <label>Canal Vendas</label>
                    <select className="form-control" id="canal">
                        <option id="haut">HAUT</option>
                        <option id="parceiro">Parceiros</option>
                        <option id="vendaDireta">Venda Direta</option>
                        <option id="lead">Lead</option>
                        <option id="corretor">Corretor</option>
                        <option id="null">Outros</option>
                    </select>
                    <label>Motivação</label>
                    <select id="motivacao">
                        <option value="investir">Investir</option>
                        <option value="morar">Morar</option>
                        <option value="outro">Outro</option>
                    </select>
                    <label>Número de parcelas</label>
                    <input className="form-control" id="parcelas" type="number" placeholder="Número de parcelas" />
                    <label>Comissão</label>
                    <label >Jantar</label>
                    <select className="form-control" id="isJantar">
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                    <label>Tipo de Permuta</label>
                    <input className="form-control" id="tipoPermuta" type="text" placeholder="Tipo de Permuta" />
                    <label>Decisão compra</label>
                    <input className="form-control" id="decisaoCompra" type="text" placeholder="Decisão compra" />
                    <label>Valor recebido permuta</label>
                    <input className="form-control" id="valorRecebidoPermuta" type="number" placeholder="Valor recebido permuta" />
                    <label>Valor recebido financeiro</label>
                    <input className="form-control" id="valorRecebidoFinanceiro" type="number" placeholder="Valor recebido financeiro" />
                    <label>Total a receber</label>
                    <input className="form-control" id="totalAReceber" type="number" placeholder="Total a receber" />
                    <label>Total a receber permuta</label>
                    <input className="form-control" id="totalAReceberPermuta" type="number" placeholder="Total a receber permuta" />
                    <label>Total a receber financeiro</label>
                    <input className="form-control" id="totalAReceberFinanceiro" type="number" placeholder="Total a receber financeiro" />
                    <label>Total recebido</label>
                    <input className="form-control" id="totalRecebido" type="number" placeholder="Total recebido" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='dark rounded' onClick={this.salvarAlteracao}> Salvar alterações</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
