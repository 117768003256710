import React, { Component } from "react";
import Dashboard from '../Dashboard/Dashboard.js'
import { BootstrapTable, TableHeaderColumn, InsertModalFooter } from 'react-bootstrap-table';
import APIHaut from '../../../http/hautDigital.js';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
   
export default class Usuario extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            data: [],           
            nome: '',
            login:'',
            senha: ''
        }   
        
        this.onToogle = this.onToogle.bind(this);
        this.beforeSave = this.beforeSave.bind(this);
    }
    
    onToogle() {
        this.setState({ opened: !this.state.opened })
    }
    componentDidMount(){
        APIHaut.get("/usuarios").then(res => {
            var arrayCorrigido = []	
            res.data.map(usuarios => {
                APIHaut.get(`/pessoas/${usuarios.id_pessoa}`).then(resp => {
                arrayCorrigido.push({
                    id_pessoa: usuarios.id_pessoa,
                    id: usuarios.id,
                    nome : resp.data.nome,
                    login: usuarios.login,
                    senha: usuarios.senha });
                    this.setState({data: arrayCorrigido});
            }).catch(er => {
                Toast.fire({
                  icon: 'error',
                  title: 'Não conseguimos recuperar alguns dados. Por favor atualize a página.'
                })
                })
            });            
        }).catch(err => {
            Toast.fire({
                icon: 'error',
                title: 'Não conseguimos atualizar os dados no momento, por favor atualize a página ou contate a equipe de TI.'
              })
        })
    }    
    onDeleteRow(rows) {
        rows.forEach(id => {
            APIHaut.delete(`/pessoas/${id}`)
            .then(res => {})
            .catch(err => {
                console.log(err);
            });
        });
    }
    afterSaveCell(row, cellName, cellValue) {
        let data = {};
        data[cellName] = cellValue;
        APIHaut.patch(`/usuarios/${row.id}`, data)
        .then(res => {})
        .catch(err => {
            console.log(err);
        });
    }
    
    beforeSave(e) {
        // buscando endereço na api viacep
        const inputNome = document.querySelector('input.editor.edit-text[placeholder=Nome]');
        const inputLogin = document.querySelector('input.editor.edit-text[placeholder=Login]');
        const inputSenha = document.querySelector('input.editor.edit-number[placeholder=Senha]');

        if ( inputLogin === null || inputSenha === null || inputNome === null) {
            Toast.fire({
                icon: 'error',
                title: 'Verifique se não há algum campo em branco.'
              })
        }
            APIHaut.post('/usuarios', {
               nome: inputNome.value,
               login: inputLogin.value,
               senha: inputSenha.value
            }).then(res => {
               Toast.fire({
                  icon: 'success',
                  title: 'Usuário salvo!'
               });
               window.location.reload();
            }).catch(err => {
            
            });
      }

    createCustomModalFooter = () => {
        return (
          <InsertModalFooter        
          beforeSave={ this.beforeSave }/>
        )
      }

    render() {   
        const selectRow = {
            mode: 'checkbox'
          };
          const options = {
            onDeleteRow: this.onDeleteRow,
            deleteText: 'Remover usuarios',
            insertText: 'Adicionar usuario',
            insertModalFooter: this.createCustomModalFooter,
          };
          const cellEdit = {
            mode: 'dbclick',
            blurToSave: true,         
            afterSaveCell: this.afterSaveCell,  
          };  
        return ( 
            <div>               
               <Dashboard onToogle={this.onToogle} />
               <div className={`main ${this.state.opened ? "sidebar-opened" : "sidebar-closed"}`}> 
               <BootstrapTable
                        data={ this.state.data } 
                        insertRow 
                        pagination
                        options={ options }
                        striped={true}
                        search
                        selectRow={ selectRow }
                        hover={true}
                        deleteRow
                        cellEdit={ cellEdit } 
                        >
                            <TableHeaderColumn dataField="id_pessoa" isKey hidden hiddenOnInsert width='100' dataAlign="center" dataSort={true} editable={false} >ID pessoa</TableHeaderColumn>
                        <TableHeaderColumn dataField="id" hidden width='50' className="point" dataAlign="center" dataSort={true} editable={false} hiddenOnInsert>ID</TableHeaderColumn>     
                        <TableHeaderColumn dataField="nome" width='150' className="point" dataSort={true} editable={ { type: 'text' } }>Nome</TableHeaderColumn>               
                        <TableHeaderColumn dataField="login" width='150' className="point" dataSort={true} editable={ { type: 'text' } }>Login</TableHeaderColumn>
                        <TableHeaderColumn dataField="senha" width='700' className="point" dataSort={true} editable={ { type: 'number' } }>Senha</TableHeaderColumn>       
                </BootstrapTable>

                </div>
            </div>
        )        
    }
}