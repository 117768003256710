import React, { Component } from "react";
import './Login.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import  building00 from '../../../img/building00.jpg';
import  building01 from '../../../img/building01.jpg';
import  building02 from '../../../img/building02.jpg';
import  building03 from '../../../img/building03.png';
import  building04 from '../../../img/building04.jpg';


export default class Login extends Component {  

    render() {     
        return ( 
            <div>     
                <div className="behind"></div>
                <div className="frontScreen">
                    <div className="leftSide">
                        {/* <img src={ hautFundo }></img><br></br> */}
                        <span>Entrar</span>
                        <br></br>
                        <label>ID do usuário</label>
                        <input type = "text" placeholder = "&#xf007;  Id" id="inputId"/>
                        <label>Senha</label>
                        <input type = "password" placeholder = "&#xf084;  Senha" id="inputPass"/>
                        <a href="/"><u>Esqueceu a senha?</u></a>
                        <button><a href="/dashboard"> ENTRAR</a> </button>
                    </div>
                    <div className="rightSide">
                    <Carousel
                    autoPlay
                    interval={5000}
                    transitionTime={400}
                    infiniteLoop
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={false}>
                        <div>
                            <img src={building03} alt="Neue João Pessoa | Hotal Boutique"/>
                        </div>
                        <div>
                            <img src={building01} alt="Marée | Bangalôs design"/>
                        </div>
                        <div>
                            <img src={building02} alt="BMRX | Loft"/>
                        </div>
                        <div>
                            <img src={building00} alt="Arbo | Townhouses"/>
                        </div>
                        <div>
                            <img src={building04} alt="CO-HAUT | 001"/>
                        </div>
                    </Carousel>
                        
                    </div>
                </div>
            </div>
        )        
    }
}