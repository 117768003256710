import React, { useState, useEffect } from 'react';
import './Autocomplete.css';

export default function Autocomplete({ suggestions, onChange, className, placeholder, handleInput }) {
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    onChange(userInput);
  }, [userInput]);

  const _onChange = e => {
    var userInput = e.currentTarget.value;

    if (typeof handleInput === "function") {
      userInput = handleInput(userInput);
    }

    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setActiveSuggestion(0);
    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(true);
    setUserInput(userInput);
  }

  const onClick = e => {
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(e.currentTarget.innerText);
  }

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(filteredSuggestions[activeSuggestion]);
    } else if (e.keyCode === 3) {
      if (activeSuggestion === 0) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestion(activeSuggestion + 1);
    }
  }

  const suggestionsList = () => {
    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        return (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a className
              if (index === activeSuggestion) {
                className = "suggestion-active";
              } else {
                className = "suggestion";
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        return (
          <div className="no-suggestions">
            <em>Nenhuma sugestão encontrada.</em>
          </div>
        );
      }
    }
  }

  return (
    <>
      <input
        className={`${className} input-autocomplete ${showSuggestions && userInput ? 'input-autocomplete-open' : 'input-autocomplete-close'}`}
        type="text"
        placeholder={placeholder}
        onChange={_onChange}
        onKeyDown={onKeyDown}
        value={userInput}
      />

      { suggestionsList() }
    </>
  );
}
