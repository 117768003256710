import React, { Component } from "react";
import './Corretor.css';
import Dashboard from '../Dashboard/Dashboard.js'
import { BootstrapTable, TableHeaderColumn, InsertModalFooter } from 'react-bootstrap-table';
import APIHaut from '../../../http/hautDigital.js';
import APICep from '../../../Services/ApiCep.js';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
   
export default class Corretor extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            data: [],
            endereco: {},
            nome: '',
            numero:'',
            pessoa: {}
        }   
        
        this.onToogle = this.onToogle.bind(this);
        this.beforeSave = this.beforeSave.bind(this);
        this.beforeSavePart2 = this.beforeSavePart2.bind(this);  
    }
    
    onToogle() {
        this.setState({ opened: !this.state.opened })
    }
    componentDidMount(){
        APIHaut.get("/corretores").then(res => {
            var arrayCorrigido = []	
            res.data.map(corretor => {
                arrayCorrigido.push({ id: corretor.id, id_pessoa: corretor.id_pessoa, nome : corretor.data.nome });
                return null
            });
            this.setState({data: arrayCorrigido});
        }).catch(err => {
            Toast.fire({
                icon: 'error',
                title: 'Não conseguimos atualizar os dados no momento, por favor atualize a página ou contate a equipe de TI.'
              })
        })
    }    
    onDeleteRow(rows) {
        rows.forEach(id => {
            APIHaut.delete(`/corretores/${id}`)
            .then(res => {})
            .catch(err => {
                console.log(err);
            });
        });
    }
    afterSaveCell(row, cellName, cellValue) {
        let data = {};
        data[cellName] = cellValue;
        APIHaut.patch(`/pessoas/${row.id_pessoa}`, data)
        .then(res => {})
        .catch(err => {
            console.log(err);
        });
    }
    
    beforeSave(e) {
        // buscando endereço na api viacep
        const inputNome = document.querySelector('input.editor.edit-text[placeholder=Nome]');
        const inputCEP = document.querySelector('input.editor.edit-number[placeholder=CEP]');
        const inputnumero = document.querySelector('input.editor.edit-number[placeholder=Numero]').value;

        if (inputNome === null || inputCEP === null || inputnumero === null) {
            Toast.fire({
                icon: 'error',
                title: 'Verifique se não há algum campo em branco.'
              })
        }

        const nome = inputNome.value;
        this.setState( { nome });

        var cep = inputCEP.value;

        // tem que tirar o tracinho do cep
        if (cep.indexOf('-') !== -1) {
            cep = cep.replace(/-/g, '');
        }

        APICep.SearchCep(cep, inputnumero)
        .then(res => {
            this.setState({ endereco: {
                logradouro: res.data.logradouro,
                cep: cep,
                bairro: res.data.bairro,
                cidade: res.data.localidade,
                uf: res.data.uf,
                numero: inputnumero,
            }});
            this.beforeSavePart2(true);
        }).catch(err => {
            Toast.fire({
                icon: 'error',
                title: 'Verifique se o CEP foi digitado corretamente..'
              })
            this.beforeSavePart2(false);
        });
      }

      beforeSavePart2(salvouCep) {
        // salvando endereço na api
        APIHaut.post('/enderecos', this.state.endereco)
        .then(res => {
            this.setState( { endereco: res.data });
            // salvando pessoa
            APIHaut.post('/pessoas', {
                id_endereco: this.state.endereco.id,
                nome: this.state.nome,
                numero: this.state.numero,              
            }).then(res => {
                // salvando finalmente o corretor
                APIHaut.post('/corretores', {
                    id_pessoa: res.data.id
                });

                Toast.fire({
                    icon: 'success',
                    title: 'Corretor cadastrado com sucesso.'
                  })
                  window.location.reload();
            }).catch(err => {
                Toast.fire({
                    icon: 'error',
                    title: 'Não foi possível completar o procedimento. Tente novamente.'
                  })
            });
        }).catch(err => {
            Toast.fire({
                icon: 'error',
                title: 'Verifique se o endereço foi digitado corretamente.'
              })
        });
      }

    createCustomModalFooter = () => {
        return (
          <InsertModalFooter        
          beforeSave={ this.beforeSave }/>
        )
      }

    render() {   
        const selectRow = {
            mode: 'checkbox'
          };
          const options = {
            onDeleteRow: this.onDeleteRow,
            deleteText: 'Remover corretor',
            insertText: 'Adicionar corretor',
            exportCSVText: 'Exportar para Excel',
            insertModalFooter: this.createCustomModalFooter,
          };
          const cellEdit = {
            mode: 'dbclick',
            blurToSave: true,         
            afterSaveCell: this.afterSaveCell,  
          };  
        return ( 
            <div>               
               <Dashboard onToogle={this.onToogle} />
               <div className={`main ${this.state.opened ? "sidebar-opened" : "sidebar-closed"}`}> 
               <BootstrapTable
                        data={ this.state.data } 
                        insertRow 
                        pagination
                        options={ options }
                        striped={true}
                        search
                        selectRow={ selectRow }
                        hover={true}
                        deleteRow
                        cellEdit={ cellEdit } 
                        >
                        <TableHeaderColumn dataField="id_pessoa" className="point" dataAlign="center" dataSort={true} hidden hiddenOnInsert>ID pessoa</TableHeaderColumn>
                        <TableHeaderColumn dataField="id" isKey width='100' className="point" dataAlign="center" dataSort={true} editable={false} hiddenOnInsert>ID</TableHeaderColumn>                        
                        <TableHeaderColumn dataField="nome" width='900' className="point" dataSort={true} editable={ { type: 'text' } }>Nome</TableHeaderColumn>
                        <TableHeaderColumn dataField="cep" width='280' hidden className="point" dataSort={true} editable={ { type: 'number' } }>CEP</TableHeaderColumn>
                        <TableHeaderColumn dataField="numero" hidden editable={ { type: 'number' } }>Numero</TableHeaderColumn>                 
                </BootstrapTable>

                </div>
            </div>
        )        
    }
}