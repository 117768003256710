import React, { Component } from "react";
import format from 'date-fns/format'
import './Unidades.css';
import Dashboard from '../Dashboard/Dashboard.js';
import BuscarCep from '../../../Components/BuscarCep.js';
import { BootstrapTable, TableHeaderColumn, InsertModalFooter } from 'react-bootstrap-table';
import APIHaut from '../../../http/hautDigital.js';
import BotaoEdit from './BotaoEdit.js';
import BotaoSave from './BotaoSave.js';
import Swal from 'sweetalert2';
import Toast from '../../../mixins/AlertToast';
import ApiCep from "../../../Services/ApiCep";

const jobTypes = ['P', 'M', 'G'];

export default class Unidades extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contador: 0,
            clientes: [],
            isDisabled: true,
            empreendimento: {
                nome: '',
                qtdUnidades: '',
                endereco: {
                    cep: '',
                    logradouro: '',
                    numero: '',
                    bairro: '',
                    cidade: '',
                    uf: ''
                }
            },
        };

        this.onToogle = this.onToogle.bind(this);
        this.onDisable = this.onDisable.bind(this);
        this.nomeEmpreendimentoHandle = this.nomeEmpreendimentoHandle.bind(this);
        this.qtdUnidadesHandle = this.qtdUnidadesHandle.bind(this);
        this.cepHandle = this.cepHandle.bind(this);
        this.numeroHandle = this.numeroHandle.bind(this);
        this.onCepChange = this.onCepChange.bind(this);
        this.save = this.save.bind(this);
        this.beforeSave = this.beforeSave.bind(this);
        this.pushUnidadeRequest = this.pushUnidadeRequest.bind(this);
        this.addUnidade = this.addUnidade.bind(this);
    }

    pushUnidadeRequest(id_empreendimento) {
        APIHaut.post('/unidades/push', {
            id_empreendimento
        })
            .then(res2 => {
                if (res2.status === 201) {
                    this.addUnidade(res2.data);
                    this.setState({ contador: this.state.contador + 1 }, () => {
                        document.querySelector('#jafoi-qtd').innerHTML = this.state.contador
                        document.querySelector('#faltam-qtd').innerHTML = this.state.empreendimento.qtdUnidades - this.state.contador
                        this.pushUnidadeRequest(id_empreendimento);
                    });
                }
            })
            .catch(err => {
                if (err.response.status === 403) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Prontinho, unidades criadas!',
                        html: 'Esta mensagem vai se fechar automaticamente em 1 segundo',
                        timer: 1500
                    });
                }
            });
    }

    addUnidade(unidade) {
        this.setState({ empreendimento: { ...this.state.empreendimento, unidades: [...this.state.empreendimento.unidades, unidade] } });
    }

    componentDidMount() {
        let slug = null;
        try {
            if (this.props.match.params.slug !== undefined) {
                slug = this.props.match.params.slug;
            }
        } catch (e) { }

        if (slug !== null) {
            APIHaut.get(`/empreendimentos/${slug}`)
                .then(res => {
                    if (res.status === 200) {
                        this.setState({ empreendimento: res.data });
                        const id_empreendimento = this.state.empreendimento.id;

                        if (res.data.unidades.length < res.data.qtdUnidades) {
                            this.setState({ contador: res.data.unidades.length });

                            Swal.fire({
                                title: 'Criando unidades',
                                footer: `Já foi <span class="ml-1" id="jafoi-qtd">${this.state.contador}</span>, faltam <span class="ml-1" id="faltam-qtd">${res.data.qtdUnidades - this.state.contador}</span>`,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                                showConfirmButton: false,
                                onOpen: function () {
                                    Swal.showLoading();
                                }
                            });

                            this.pushUnidadeRequest(id_empreendimento);
                        }

                        APIHaut.get(`/clientes/empreendimentos/${res.data.id}`)
                            .then(res2 => {
                                this.setState({ clientes: res2.data });
                            })
                            .catch(err => {
                                console.log(err);
                            });
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    onToogle() {
        this.setState({ opened: !this.state.opened })
    }

    onDisable() {
        this.setState({ isDisabled: !this.state.isDisabled })
        if (!this.state.isDisabled) {
            this.save();
        }
    }

    nomeEmpreendimentoHandle(payload) {
        this.setState({
            empreendimento: { ...this.state.empreendimento, nome: payload }
        });
    }

    qtdUnidadesHandle(payload) {
        this.setState({
            empreendimento: { ...this.state.empreendimento, qtdUnidades: payload }
        });
    }

    cepHandle(payload) {
        this.setState({
            empreendimento: {
                ...this.state.empreendimento,
                endereco: { ...this.state.empreendimento.endereco, cep: payload }
            }
        });
    }

    numeroHandle(payload) {
        this.setState({
            empreendimento: {
                ...this.state.empreendimento,
                endereco: { ...this.state.empreendimento.endereco, numero: payload }
            }
        });
    }

    onCepChange(endereco) {
        this.setState({
            empreendimento: {
                ...this.state.empreendimento,
                endereco: {
                    ...this.state.empreendimento.endereco,
                    ...endereco
                }
            }
        });
    }

    save() {
        APIHaut.patch(`/empreendimentos/${this.state.empreendimento.id}`, {
            nome: this.state.empreendimento.nome,
            qtdUnidades: this.state.empreendimento.qtdUnidades,
            custoUnidade: this.state.empreendimento.custoUnidade
        })
            .then(res => {
                if (res.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Empreendimento salvo com sucesso'
                    });
                }
            })
            .catch(err => {
                Toast.fire({
                    icon: 'error',
                    title: 'Erro ao salvar o empreendimento'
                });
            });

        APIHaut.patch(`/enderecos/${this.state.empreendimento.endereco.id}`, this.state.empreendimento.endereco)
            .then(res => {
                if (res.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Endereço salvo com sucesso'
                    });
                }
            })
            .catch(err => {
                Toast.fire({
                    icon: 'error',
                    title: 'Erro ao salvar o endereço'
                });
            });
    }

    beforeSave() {
        const numero = document.querySelector("input[placeholder=Unidade]").value;
        const area = document.querySelector("input[placeholder=Área]").value;
        const precoTabela = document.querySelector("input[placeholder='Preço de Tabela']").value;
        const id_empreendimento = this.state.empreendimento.id;

        APIHaut.post("/unidades", {
            numero,
            area,
            precoTabela,
            id_empreendimento
        })
        .then(res => {
            if (res.status === 201) {
                this.setState({
                    empreendimento: {
                        ...this.state.empreendimento,
                        unidades: [
                            ...this.state.empreendimento.unidades,
                            res.data
                        ]
                    }
                });
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    onDeleteRow(rows) {
        rows.forEach(id => {
            APIHaut.delete(`/unidades/${id}`)
                .then(res => { })
                .catch(err => {
                    console.log(err);
                });
        });
    }

    afterSaveCell(row, cellName, cellValue) {
        let data = {};
        data[cellName] = cellValue;
        APIHaut.patch(`/unidades/${row.id}`, data)
            .then(res => { })
            .catch(err => {
                console.log(err);
            });
    }

    createCustomModalFooter = () => {
        return (
            <InsertModalFooter beforeSave={this.beforeSave} />
        );
    }

    showData(cell, row, data) {
        if (data === 'dataNascimento') {
            return format(new Date(cell[data]), 'dd/MM/yyyy');
        }

        return cell[data];
    }

    render() {
        const selectRow = {
            mode: 'checkbox'
        };

        const options = {
            onDeleteRow: this.onDeleteRow,
            // insertModal: this.createCustomModal,
            paginationSize: 3,
            sortIndicator: true,
            deleteText: 'Remover unidade',
            insertText: 'Adicionar unidade',
            exportCSVText: 'Exportar para Excel',
            insertModalFooter: this.createCustomModalFooter
        };

        const optionsClientes = {
            // onDeleteRow: this.onDeleteRow,
            // insertModal: this.createCustomModal,
            paginationSize: 3,
            sortIndicator: true,
            // deleteText: 'Remover unidade',
            // insertText: 'Adicionar unidade',
            exportCSVText: 'Exportar para Excel',
            // insertModalFooter: this.createCustomModalFooter
        };

        const cellEdit = {
            mode: 'dbclick',
            blurToSave: true,
            afterSaveCell: this.afterSaveCell
        };

        return (
            <div>
                <Dashboard onToogle={this.onToogle} />
                <div className={`main ${this.state.opened ? "sidebar-opened" : "sidebar-closed"}`} id="empreendimento">
                    <div className="row">
                        <div className="col col-title p-20">
                            {this.state.empreendimento.nome}
                        </div>
                    </div>

                    <div className="row">
                        <div className="cardDash col p-20 rounded">
                            <div className="row p-20">
                                <div className="peer peer-right w-100 legend-card">
                                    <button style={{ border: 'none' }} onClick={this.onDisable}>
                                        {this.state.isDisabled === true ? (<BotaoEdit />) : this.state.isDisabled === false ? (<BotaoSave onClick={this.save} />) : null}</button>
                                </div>
                                <div className="w-50">
                                    Nome do empreendimento
                                </div>
                                <div className="w-25">
                                    Número de unidades
                                </div>
                                <div className="w-25">
                                    Custo da Unidade
                                </div>
                            </div>

                            <div className="row p-20">
                                <div className="w-50">
                                    <input
                                        className="w-97"
                                        type="text"
                                        value={this.state.empreendimento.nome}
                                        id="inputName"
                                        disabled={this.state.isDisabled}
                                        onChange={e => this.nomeEmpreendimentoHandle(e.target.value)}
                                    />
                                </div>

                                <div className="w-25">
                                    <input
                                        className="w-95"
                                        type="number"
                                        value={this.state.empreendimento.qtdUnidades}
                                        id="inputUni"
                                        disabled={this.state.isDisabled}
                                        onChange={e => this.qtdUnidadesHandle(e.target.value)}
                                    />
                                </div>
                                <div className="w-25">
                                    <input
                                        className="w-95"
                                        type="number"
                                        value={this.state.empreendimento.custoUnidade}
                                        id="inputCustoUnidade"
                                        disabled={this.state.isDisabled}
                                        onChange={e => this.setState({ empreendimento: { ...this.state.empreendimento, custoUnidade: e.target.value } })}
                                    />
                                </div>
                            </div>

                            <BuscarCep
                                onDisable={this.state.isDisabled}
                                endereco={this.state.empreendimento.endereco}
                                cepHandle={this.cepHandle}
                                numeroHandle={this.numeroHandle}
                                onChange={this.onCepChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-title p-20">
                            Unidades
                        </div>
                    </div>
                    <div className="row">
                        <div className="cardDash w-100 tabela p-20" id="tabelaUnidade">
                            <BootstrapTable
                                data={this.state.empreendimento.unidades}
                                insertRow
                                pagination
                                options={options}
                                striped={true}
                                exportCSV={true}
                                csvFileName='Unidades-Empreendimento'
                                search
                                selectRow={selectRow}
                                hover={true}
                                deleteRow
                                cellEdit={cellEdit}
                            >
                                <TableHeaderColumn dataField="id" width='50' isKey={true} dataAlign="center" dataSort={true} editable={false}>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField="numero" width='150' dataSort={true} editable={{ type: 'text' }}>Unidade</TableHeaderColumn>
                                <TableHeaderColumn dataField="area" width='150' dataSort={true} editable={{ type: 'text' }}>Área</TableHeaderColumn>
                                <TableHeaderColumn dataField="precoTabela" width='150' dataSort={true} editable={{ type: 'text' }}>Preço de Tabela</TableHeaderColumn>
                                {/* <TableHeaderColumn dataField="empreendimento" width='150' dataSort={true} editable={ false }>Empreendimento</TableHeaderColumn> */}
                                {/* <TableHeaderColumn dataField="altura" width='150' dataSort={true} editable={ { type: 'number' } }>Altura</TableHeaderColumn>
                        <TableHeaderColumn dataField="base" width='150' dataSort={true} editable={ { type: 'number' } }>Base</TableHeaderColumn> */}
                            </BootstrapTable>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-title p-20">
                            Clientes
                        </div>
                    </div>
                    <div className="row">
                        <div className="cardDash w-100 tabela p-20" id="tabelaClientes">
                            <BootstrapTable
                                data={this.state.clientes}
                                pagination
                                options={optionsClientes}
                                striped={true}
                                exportCSV={true}
                                csvFileName={`clientes-empreendimento-${this.state.empreendimento.nome}`}
                                search
                                selectRow={selectRow}
                                hover={true}
                            >
                                <TableHeaderColumn dataField="id" width='50' isKey={true} dataAlign="center" dataSort={true} editable={false}>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField="data" width='150' dataSort={true} dataFormat={(cell, row) => this.showData(cell, row, 'nome')} editable={{ type: 'text' }}>Nome</TableHeaderColumn>
                                <TableHeaderColumn dataField="data" width='150' dataSort={true} dataFormat={(cell, row) => this.showData(cell, row, 'profissao')} editable={{ type: 'text' }}>Profissão</TableHeaderColumn>
                                <TableHeaderColumn dataField="data" width='150' dataSort={true} dataFormat={(cell, row) => this.showData(cell, row, 'estadoCivil')} editable={{ type: 'text' }}>Estado Civil</TableHeaderColumn>
                                <TableHeaderColumn dataField="data" width='150' dataSort={true} dataFormat={(cell, row) => this.showData(cell, row, 'dataNascimento')} editable={{ type: 'date' }}>Data de Nascimento</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
