import React, { Component } from "react";
import './Dashboard.css';
import { faUserFriends} from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import h from '../../../img/h.jpg'

export default class Dashboard extends Component {    
    constructor(props) {
        super(props);
        this.state = {short: true}
    }
    changeSize(){
        this.setState({short: !this.state.short});
        this.props.onToogle();
    }

    render() {
        let sidebar = this.state.short ? "sidebar" : "sidebarShort";
        let boxImg = this.state.short ? "boxImg" : "boxImgShort";
        return ( 
           
                <div className="header">
                    <div className="header-its">      
                        <div className={boxImg} id="logoImg">    
                        <a href="/dashboard"><img src={ h } alt="H haut"/></a>                        
                                              
                    </div>   
                    <FontAwesomeIcon icon={faBars} size="1x" onClick={() => this.changeSize(this.props.mySidebar, this.props.logoImg)} style={{marginLeft: "10px", cursor: "pointer", transition: "0.5s"}}/>
                    <div className="notificacao">
                        <i className="far fa-bell" style={{fontSize: "25px", color: "rgb(0, 0, 0)"}}></i>
                    </div>
                    <Dropdown id="dropDash">
                    <DropdownButton id="dropdown-basic-button" title="Administrador">
                        <Dropdown.Item href="/dashboard">Configurações</Dropdown.Item>
                        <Dropdown.Item href="#/dashboard">Perfil</Dropdown.Item>
                        <Dropdown.Item href="#/">Logout</Dropdown.Item>
                        </DropdownButton>
                    </Dropdown>
                    </div>
                <div id="mySidebar" className={sidebar}>
                        <div className="icones">
                        <ul>
                            <li><a href="/dashboard"><FontAwesomeIcon icon={faHome} />Dashboard</a></li>
                            <li><a href="/empreendimentos"><FontAwesomeIcon icon={faBuilding} style={{marginLeft: "18px", marginRight : "27px"}}/>Empreendimentos</a></li>
                            <li><a href="/clientes"><FontAwesomeIcon icon={faUserFriends} style={{marginRight : "22px"}}/>Clientes</a></li>
                            <li><a href="/corretores"><FontAwesomeIcon icon={faUserTie} style={{marginLeft: "19px", marginRight : "24px"}}/>Corretores</a></li>
                            <li><a href="/vendas"><FontAwesomeIcon icon={faDollarSign} style={{marginLeft: "23px", marginRight : "28px"}}/>Vendas</a></li>
                        </ul>
                    </div>         
                </div>           
            </div>
        )        
    }
}