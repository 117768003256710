import React from 'react';
import ApiCep from '../Services/ApiCep';
import "./BuscaCep.css";



class BuscaCep extends React.Component {
  constructor() {
    super();

    this.state = {
        rua: 'Rua',
        number: "",
       bairro: 'Bairro',
       cidade: 'Cidade',
       estado: 'Estado',
    }
  }

  handleDados(e) {
    // Pegando o CEP
    const cep = e.target.value;
    // Consultando a API
    ApiCep.SearchCep(cep).then((res) => {
      let rua       = res.data.logradouro;
      let bairro    = res.data.bairro;
      let cidade    = res.data.localidade;
      let estado    = res.data.uf;
      // Mudando o estado
      this.setState({
        rua: rua,
        bairro: bairro,
        cidade: cidade,
        estado: estado,
      })
      this.props.getValue(
        {
          cep: cep,
          numero: this.state.number,
          rua: rua,
          bairro: bairro,
          cidade: cidade,
          estado: estado,
        }
      )
    })
  }
  setNumber(val){
    this.setState({...this.state, numero: val})
}

  render() {
    return(
      <div>
          <form id="form-Cep">
          <label>CEP</label>         
                <input type="text" onBlur={ this.handleDados.bind(this) } required="true" placeholder="Cep" />              

                <label>Logadouro</label>          
                <input type="text" value={ this.state.rua} disabled/>              

                <label>Número</label>            
                <input type="number" required="true" placeholder="Número" onChange={e => {this.setNumber(e.target.value)}}/>              

                <label>Bairro</label>        
                <input type="text" value={ this.state.bairro} disabled/>              

                <label>Cidade</label>             
                <input type="text" value={ this.state.cidade} disabled/>             

                <label>Estado</label>            
                <input type="text"  value={ this.state.estado} disabled/>              
          </form>
      </div>
    );
  }
}

export default BuscaCep;