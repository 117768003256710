import React from 'react';
import EmpreendimentosLine from './EmpreendimentosLine';

class EmpreendimentosRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lines: []
    }

    this.createLines = this.createLines.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.empreendimentos.length !== prevProps.empreendimentos.length) {
      this.createLines();
    }
  }

  handleDelete(id) {
    this.props.handleDelete(id);
  }

  createLines() {
    let line = 0;
    let cols = 0;
    let fakelines = [];
    
    for (let i = 0; i < this.props.empreendimentos.length; i++) {
      if (fakelines[line] === undefined) {
        fakelines[line] = [];
      }
      
      fakelines[line][cols] = this.props.empreendimentos[i];
      
      if (cols === 2) {
        cols = 0;
        line++;
      } else {
        cols++;
      }
    }

    this.setState({ lines: fakelines });
  }

  render() {
    return(
      <>
        { this.state.lines.map((line, index) => {
          return <EmpreendimentosLine empreendimentos={line} key={index} handleDelete={this.handleDelete} />
        }) }
      </>
    );
  }
}

export default EmpreendimentosRow;
