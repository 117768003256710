import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
// import Home from './pages/home/Home.jsx'
import Vendas from './pages/Vendas/Vendas.js'
import Empreendimentos from './pages/Vendas/Empreendimentos/Empreendimentos.js'
import Venda from './pages/Vendas/Venda/Venda.js'
import Clientes from './pages/Vendas/Clientes/Clientes.js'
import Unidades from './pages/Vendas/Unidades/Unidades.js'
import Corretor from './pages/Vendas/Corretor/Corretor.js'
import Login from './pages/Vendas/Login/Login.js'
import Usuario from './pages/Vendas/Usuarios/Usuario.js'

export default function Router() {
    return (
        <BrowserRouter>
            <Route exact path="/" component={Vendas} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/dashboard" component={Vendas} />
            <Route exact path="/empreendimentos" component={Empreendimentos} />
            <Route exact path="/empreendimentos/:slug" component={Unidades} />
            <Route exact path="/vendas" component={Venda} />
            <Route exact path="/clientes" component={Clientes} />
            <Route exact path="/corretores" component={Corretor} />
            <Route exact path="/usuario" component={Usuario} />
        </BrowserRouter>
    )
}
