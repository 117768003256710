import React, { Component } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class BotaoSave extends Component {    
    render() {          
        return (
            <div>
                <div className="myButton"><FontAwesomeIcon className="mr-3" icon={faPen}/>Editar</div>
                </div>

         )
    }
}
