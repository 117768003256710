import React from 'react';
import EmpreendimentoCard from './EmpreendimentosCard';

class EmpreendimentosLine extends React.Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete(id) {
    this.props.handleDelete(id);
  }

  render() {
    return(
      <div className="row">
        { this.props.empreendimentos.map(empreendimento => {
          return(
              <EmpreendimentoCard empreendimento={empreendimento} handleDelete={this.handleDelete} key={empreendimento.id} />
          )
        }) }
      </div>
    );
  }
}

export default EmpreendimentosLine;
