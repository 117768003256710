import React, { Component } from "react";
import './Empreendimentos.css';
import Dashboard from '../Dashboard/Dashboard.js'
import EmpreendimentosRow from '../../../Components/EmpreendimentosCard/EmpreendimentosRow';
import { AdicionarEmpreendimento } from './AdicionarEmpreendimento.js';
import APIHaut from '../../../http/hautDigital.js';
import Toast from '../../../mixins/AlertToast';

export default class Empreendimentos extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            confirmarModal: false, 
            adicionarModal: false,
            empreendimentos: [],         
            }   
        
        this.onToogle = this.onToogle.bind(this);
        this.addNewEmpreendimento = this.addNewEmpreendimento.bind(this);
        this.deleteEmpreendimento = this.deleteEmpreendimento.bind(this);
    }

    componentDidMount(){
        APIHaut.get("/empreendimentos").then(res => {
            this.setState({...this.state, empreendimentos: res.data})
        })
    }
    
    onToogle() {
        this.setState({ opened: !this.state.opened });
    }

    addNewEmpreendimento(empreendimento) {
        this.setState({
            empreendimentos: [ ...this.state.empreendimentos, empreendimento ],
            adicionarModal: false
        });
    }

    deleteEmpreendimento(id) {
        APIHaut.delete(`/empreendimentos/${id}`)
        .then(res => {
            if (res.status === 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Excluído com sucesso!'
                });

                this.setState({ empreendimentos: this.state.empreendimentos.filter(empreendimento => empreendimento.id !== id) });
            }
        })
        .catch(err => {
            console.log(err);
            Toast.fire({
                icon: 'error',
                title: 'Não foi possível apagar o empreendimento'
            });
        });
    }

    render() {     
        let adicionarModalClose =() => this.setState({adicionarModal:false});
        return ( 
            <div>               
               <Dashboard onToogle={this.onToogle} />
               <div className={`main ${this.state.opened ? "sidebar-opened" : "sidebar-closed"}`} id="empreendimento"> 
                    <div className="row">
                        <div className="col col-title p-20">
                            Empreendimentos
                        </div>
                    </div>
                    <div className="row p-20 flex-row-reverse">
                        <a onClick={()=> this.setState({adicionarModal: true})}>
                            <button type="button" className="btn btn-dark shadow rounded">+ Adicionar Empreendimento</button>
                        </a>
                        <AdicionarEmpreendimento show={this.state.adicionarModal} onHide={adicionarModalClose} onSave={this.addNewEmpreendimento} />
                    </div>
                    <EmpreendimentosRow empreendimentos={this.state.empreendimentos} handleDelete={this.deleteEmpreendimento} />
               </div>
            </div>
        )        
    }
}