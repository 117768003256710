import React from 'react';
import { ModalConfirmar } from '../../pages/Vendas/Empreendimentos/ModalConfirmar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

class EmpreendimentosCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false
    }

    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  onConfirm() {
    this.closeModal();
    this.props.handleDelete(this.props.empreendimento.id);
  }

  onCancel() {
    this.closeModal();
  }

  openModal() {
    this.setState({ openModal: true }, () => {
      console.log(this.state.openModal, 'open');
    });
  }

  closeModal() {
    this.setState({ openModal: false }, () => {
      console.log(this.state.openModal);
    });
  }

  render() {
    return(
      <>
        <div className="cardDash col p-20 rounded point">
          <hr className="linha-vertical h-75" style={{position: "absolute"}} />
          <div className="ml-4">
            <a className="text-dark" href={`/empreendimentos/${this.props.empreendimento.slug}`}>
              <h6 className="1h-1 black">{ this.props.empreendimento.nome }</h6>
            </a>
            <label className="legend-card">{ this.props.empreendimento.qtdUnidades } unidades</label><br />
            <label className="legend-card">
              { this.props.empreendimento.endereco.logradouro }, s/n, { this.props.empreendimento.endereco.bairro } - { this.props.empreendimento.endereco.cidade } / { this.props.empreendimento.endereco.uf }
            </label>
            <div className="peer peer-right w-100 legend-card">
              <button className="bg-transparent border-0 text-center mr-3" onClick={this.openModal}>
                <FontAwesomeIcon className="" icon={faTrash} />
              </button>
            </div>
          </div>
        </div>
        <ModalConfirmar
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
          show={this.state.openModal}
        />
      </>
    )
  }
}

export default EmpreendimentosCard;
