import React, { Component } from "react";
import './Venda.css';
import Dashboard from '../Dashboard/Dashboard.js'
import { BootstrapTable, TableHeaderColumn, InsertModalHeader } from 'react-bootstrap-table';
import ModalInserir from '../../../Components/ModalInserir.js'
import APIHaut from '../../../http/hautDigital.js'
import Swal from 'sweetalert2';
import { Modal, ButtonGroup } from 'react-bootstrap';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
const motivacaoType = ['Investir', 'Morar', 'Outro'];
export default class Venda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      modalInserir: false,
      modalToggleC: false,
      hiddenColumns: {},
      data: [],
      empreendimentoArray: [],
      usuariosArray: [],
      clientesArray: [],
    }

    this.onToogle = this.onToogle.bind(this);
    this.changeColumn = this.changeColumn.bind(this);
  }
  createCustomModalHeader = () => {
    return (
      <InsertModalHeader
        className='my-custom-class'
        title='Adicionar uma nova venda'
      />
    );
  }
  onToogle() {
    this.setState({ opened: !this.state.opened })
  }

  changeColumn(e) {
    console.log('--', e.target.id);
    const p = e.target.id;
    this.setState({
      hiddenColumns: Object.assign(this.state.hiddenColumns,
        {
          [p]: !this.state.hiddenColumns[p],
        }
      )
    });
  }
  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='my-custom-class' sizeClass='btn-group-md'>
        { props.showSelectedOnlyBtn}
        { props.exportCSVBtn}
        { props.insertBtn}
        { props.deleteBtn}
        <button type='button'
          className={`btn btn-primary`} onClick={() => this.setState({ modalToggleC: true })}>
          Colunas visíveis
                </button>
      </ButtonGroup>
    );
  }
  onDeleteRow(rows) {
    rows.forEach(id => {
      APIHaut.delete(`/vendas/${id}`)
        .then(res => { })
        .catch(err => {
          console.log(err);
        });
    });
  }
  afterSaveCell(row, cellName, cellValue) {
    let data = {};
    data[cellName] = cellValue;
    APIHaut.patch(`/vendas/${row.id}`, data)
      .then(res => { })
      .catch(err => {
        console.log(err);
      });
  }
  componentDidMount() {
    APIHaut.get("/empreendimentos").then(res => {
      res.data.map(empreendimento => {
        this.setState({
          empreendimentoArray: [
            ...this.state.empreendimentoArray,
            empreendimento.nome
          ]
        });
        return null
      });
    });
    APIHaut.get("/clientes").then(res => {
      res.data.map(clientes => {
        this.setState({
          clientesArray: [
            ...this.state.clientesArray,
            clientes.data.nome
          ]
        });
        return null
      });
    });
    APIHaut.get("/usuarios").then(res => {
      res.data.map(usuarios => {
        this.setState({
          usuariosArray: [
            ...this.state.usuariosArray,
            usuarios.login
          ]
        });
        return null
      });
    });
    APIHaut.get("/vendas").then(res => {
      function tratarData(data) {
        let stringdata = data.substring(0, 10);
        let repor = stringdata.replace(/-/g, '');
        let final = repor.substring(6, 8) + '/' + repor.substring(4, 6) + '/' + repor.substring(0, 4);
        data = final;
        return data;
      }
      function retornarSim(booleano) {
        if (booleano === true) {
          booleano = "Sim";
        } else {
          booleano = "Não";
        }
        return booleano;
      }
      res.data.map(venda => {

        APIHaut.get(`/empreendimentos/${venda.id_empreendimento}`).then(res => {
          APIHaut.get(`/clientes/${venda.id_cliente}`).then(respc => {
            let dataFormatada = tratarData(venda.dataCompra);
            let SimNao = retornarSim(venda.isJantar);
            this.setState({
              data: [
                ...this.state.data,
                {
                  id: venda.id,
                  precoVendido: venda.precoVendido,
                  fluxo: venda.fluxo,
                  dataCompra: dataFormatada,
                  canal: venda.canal,
                  valorInicialM2: venda.valorInicialM2,
                  comissao: venda.comissao,
                  valorRealM2: venda.valorRealM2,
                  motivacao: venda.motivacao,
                  isJantar: SimNao,
                  valorRecebidoFinanceiro: venda.valorRecebidoFinanceiro,
                  valorRecebidoPermuta: venda.valorRecebidoPermuta,
                  totalAReceber: venda.totalAReceber,
                  totalAReceberPermuta: venda.totalAReceberPermuta,
                  totalAReceberFinanceiro: venda.totalAReceberFinanceiro,
                  totalRecebido: venda.totalRecebido,
                  id_usuario: venda.id_usuario,
                  cliente: respc.data.data.nome,
                  id_cliente: venda.id_cliente,
                  id_unidade: venda.id_unidade,
                  id_empreendimento: venda.id_empreendimento,
                  empreendimento: res.data.nome,
                  decisaoCompra: venda.decisaoCompra,
                }
              ]
            });
          }).catch(er => {
            Toast.fire({
              icon: 'error',
              title: 'Não conseguimos recuperar alguns dados. Por favor atualize a página.'
            })
          })
        }).catch(err => {
          Toast.fire({
            icon: 'error',
            title: 'Não conseguimos recuperar alguns dados. Por favor atualize a página.'
          })
        });
        return null
      });
    }).catch(err => {
      Toast.fire({
        icon: 'error',
        title: 'Não conseguimos atualizar os dados no momento, por favor atualize a página ou contate a equipe de TI.'
      })
    })
  }

  render() {
    const selectRow = {
      mode: 'checkbox',
      width: '120px',
    };
    const options = {
      onDeleteRow: this.onDeleteRow,
      insertModal: this.createCustomModal,
      deleteText: 'Remover venda',
      insertText: 'Adicionar venda',
      exportCSVText: 'Exportar para Excel',
      insertModalHeader: this.createCustomModalHeader,
      btnGroup: this.createCustomButtonGroup,
    };
    const cellEdit = {
      mode: 'dbclick',
      blurToSave: true,
      afterSaveCell: this.afterSaveCell,
    };
    let ModalInserirClose = () => this.setState({ modalInserir: false });
    let modalToggleCClose = () => this.setState({ modalToggleC: false });
    return (
      <div>
        <Dashboard onToogle={this.onToogle} />
        <div className={`main ${this.state.opened ? "sidebar-opened" : "sidebar-closed"}`} id="venda">
          <div className="row p-20 flex-row-reverse">
            <a onClick={() => this.setState({ modalInserir: true })}>
              <button type="button" className="btn btn-dark shadow rounded">+ Adicionar venda</button></a>
            <ModalInserir show={this.state.modalInserir} onHide={ModalInserirClose} />

          </div>

          <Modal show={this.state.modalToggleC} onHide={modalToggleCClose} >
            <Modal.Header closeButton>
              <Modal.Title>Selecione as colunas para exibição</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table style={{ width: "100%" }}>
                <tr>
                  <td><label><input type="checkbox" id="corretor" onChange={this.changeColumn} checked={!this.state.hiddenColumns.corretor} />Corretor</label></td>
                  <td><label><input type="checkbox" id="valorRealM2" onChange={this.changeColumn} checked={!this.state.hiddenColumns.valorRealM2} />Valor Real m²</label></td>
                </tr>
                <tr>
                  <td><label><input type="checkbox" id="cliente" onChange={this.changeColumn} checked={!this.state.hiddenColumns.cliente} />Cliente</label></td>
                  <td><label><input type="checkbox" id="comissao" onChange={this.changeColumn} checked={!this.state.hiddenColumns.comissao} />Comissão</label></td>

                </tr>
                <tr>
                  <td><label><input type="checkbox" id="empreendimento" onChange={this.changeColumn} checked={!this.state.hiddenColumns.empreendimento} />Nome empreendimento</label></td>
                  <td><label><input type="checkbox" id="motivacao" onChange={this.changeColumn} checked={!this.state.hiddenColumns.motivacao} />Motivação</label></td>

                </tr>
                <tr>
                  <td><label><input type="checkbox" id="id_unidade" onChange={this.changeColumn} checked={!this.state.hiddenColumns.id_unidade} />ID Unidade</label></td>
                  <td><label><input type="checkbox" id="isJantar" onChange={this.changeColumn} checked={!this.state.hiddenColumns.isJantar} />Jantar</label></td>
                </tr>
                <tr>
                  <td><label><input type="checkbox" id="valorRecebidoPermuta" onChange={this.changeColumn} checked={!this.state.hiddenColumns.valorRecebidoPermuta} />Valor recebido permuta</label></td>
                </tr>
                <tr>
                  <td><label><input type="checkbox" id="precoVendido" onChange={this.changeColumn} checked={!this.state.hiddenColumns.precoVendido} />Preço vendido</label></td>
                  <td><label><input type="checkbox" id="valorRecebidoFinanceiro" onChange={this.changeColumn} checked={!this.state.hiddenColumns.valorRecebidoFinanceiro} />Valor recebido financeiro</label></td>

                </tr>
                <tr>
                  <td><label><input type="checkbox" id="dataCompra" onChange={this.changeColumn} checked={!this.state.hiddenColumns.dataCompra} />Data da compra</label></td>
                  <td><label><input type="checkbox" id="totalAReceber" onChange={this.changeColumn} checked={!this.state.hiddenColumns.totalAReceber} />Total a receber</label></td>

                </tr>
                <tr>
                  <td><label><input type="checkbox" id="totalAReceberPermuta" onChange={this.changeColumn} checked={!this.state.hiddenColumns.totalAReceberPermuta} />Total a receber permuta</label></td>
                </tr>
                <tr>
                  <td><label><input type="checkbox" id="canal" onChange={this.changeColumn} checked={!this.state.hiddenColumns.canal} />Canal</label></td>
                  <td><label><input type="checkbox" id="totalAReceberFinanceiro" onChange={this.changeColumn} checked={!this.state.hiddenColumns.totalAReceberFinanceiro} />Total a receber financeiro</label></td>
                </tr>
                <tr>
                  <td><label><input type="checkbox" id="valorInicialM2" onChange={this.changeColumn} checked={!this.state.hiddenColumns.valorInicialM2} />Valor Inicial m²</label></td>
                  <td><label><input type="checkbox" id="totalRecebido" onChange={this.changeColumn} checked={!this.state.hiddenColumns.totalRecebido} />Total recebido</label></td>
                </tr>
              </table>
            </Modal.Body>
          </Modal>

          <BootstrapTable data={this.state.data}
            width={400}
            pagination
            options={options}
            striped={true}
            search
            selectRow={selectRow}
            exportCSV={true}
            csvFileName='Vendas'
            hover={true}
            deleteRow
            cellEdit={cellEdit}
            tableBodyClass='celula-padrao'
          >
            <TableHeaderColumn dataField="id" width='80px' hidden isKey={true} className="point" dataAlign="center" dataSort={true} hiddenOnInsert>ID</TableHeaderColumn>
              <TableHeaderColumn dataField="id_usuario" width='50px' className="point" dataSort={true} hidden hiddenOnInsert>ID Embaixador</TableHeaderColumn>
            <TableHeaderColumn dataField="usuario" width='165px' className="point" dataSort={true} hidden={this.state.hiddenColumns.usuario} editable={false}>Embaixador</TableHeaderColumn>
            <TableHeaderColumn dataField="id_corretor" width='50px' className="point" dataSort={true} hidden hiddenOnInsert>ID Corretor</TableHeaderColumn>
            <TableHeaderColumn dataField="corretor" width='165px' className="point" dataSort={true} hidden={this.state.hiddenColumns.corretor} editable={{ type: 'select', options: { values: this.state.usuariosArray } }}>Corretor</TableHeaderColumn>
            <TableHeaderColumn dataField="id_empreendimento" className="point" width='160px' hidden dataSort={false} hiddenOnInsert>ID empreendimento</TableHeaderColumn>
            <TableHeaderColumn dataField="id_cliente" hidden>ID cliente</TableHeaderColumn>
            <TableHeaderColumn dataField="cliente" hidden={this.state.hiddenColumns.cliente} className="point" width='160px' dataSort={true} tdStyle={{ whiteSpace: 'break-spaces' }} editable={{ type: 'select', options: { values: this.state.clientesArray } }}>Cliente</TableHeaderColumn>
            <TableHeaderColumn dataField="empreendimento" hidden={this.state.hiddenColumns.empreendimento} className="point" width='160px' dataSort={true} tdStyle={{ whiteSpace: 'break-spaces' }} editable={{ type: 'select', options: { values: this.state.empreendimentoArray } }}>Empreendimento</TableHeaderColumn>
            <TableHeaderColumn dataField="id_unidade" width='100px' hidden={this.state.hiddenColumns.id_unidade} className="point" dataSort={true} thStyle={{ whiteSpace: 'nowrap' }} editable={{ type: 'number' }}>Unidade</TableHeaderColumn>
            <TableHeaderColumn dataField="custoUnidade" width='150px' hidden={this.state.hiddenColumns.custoUnidade} className="point" dataSort={true} thStyle={{ whiteSpace: 'nowrap' }} editable={{ type: 'number' }}>Custo unidade</TableHeaderColumn>
            <TableHeaderColumn dataField="precoVendido" width='150px' hidden={this.state.hiddenColumns.precoVendido} className="point" dataSort={true} thStyle={{ whiteSpace: 'nowrap' }} editable={{ type: 'number' }}>Preço Vendido</TableHeaderColumn>
            <TableHeaderColumn dataField="dataCompra" width='150' hidden={this.state.hiddenColumns.dataCompra} className="point" dataSort={true} thStyle={{ whiteSpace: 'nowrap' }} editable={{ type: 'text' }}>Data de compra</TableHeaderColumn>
            <TableHeaderColumn dataField="fluxo" width='150' hidden={this.state.hiddenColumns.fluxo} className="point" thStyle={{ whiteSpace: 'nowrap' }} dataSort={true} editable={{ type: 'text' }}>Fluxo</TableHeaderColumn>
            <TableHeaderColumn dataField="canal" width='150' hidden={this.state.hiddenColumns.canal} className="point" thStyle={{ whiteSpace: 'nowrap' }} dataSort={true} editable={{ type: 'select', options: { values: ['haut', 'parceiro', 'vendaDireta', 'lead', 'corretor', 'null'] } }}>Canal</TableHeaderColumn>
            <TableHeaderColumn dataField="valorInicialM2" width='150' hidden={this.state.hiddenColumns.valorInicialM2} className="point" thStyle={{ whiteSpace: 'nowrap' }} dataSort={true}>Valor inicial M2</TableHeaderColumn>
            <TableHeaderColumn dataField="valorRealM2" width='150' className="point" hidden={this.state.hiddenColumns.valorRealM2} thStyle={{ whiteSpace: 'nowrap' }} dataSort={true} editable={{ type: 'number' }}>Valor real M2</TableHeaderColumn>
            <TableHeaderColumn dataField="comissao" width='150' className="point" hidden={this.state.hiddenColumns.comissao} tdStyle={{ whiteSpace: 'nowrap' }} dataSort={true} editable={{ type: 'number' }}>Comissão</TableHeaderColumn>
            <TableHeaderColumn dataField="motivacao" width='150px' hidden={this.state.hiddenColumns.motivacao} tdStyle={{ whiteSpace: 'break-spaces' }} className="point" dataSort={true} editable={{ type: 'select', options: { values: motivacaoType } }}>Motivação</TableHeaderColumn>
            <TableHeaderColumn dataField="isJantar" width='150' className="point" hidden={this.state.hiddenColumns.isJantar} tdStyle={{ whiteSpace: 'nowrap' }} dataSort={true} editable={{ type: 'select', options: { values: ['sim', 'não'] } }}>Jantar</TableHeaderColumn>
            <TableHeaderColumn dataField="tipoPermuta" width='150' className="point" hidden={this.state.hiddenColumns.tipoPermuta} tdStyle={{ whiteSpace: 'nowrap' }} dataSort={true} editable={{ type: 'text'} }>Tipo de Permuta</TableHeaderColumn>
            <TableHeaderColumn dataField="valorRecebidoPermuta" width='150' hidden={this.state.hiddenColumns.valorRecebidoPermuta} tdStyle={{ whiteSpace: 'nowrap' }} className="point" dataSort={true} editable={{ type: 'number' }}>Valor recebido permuta</TableHeaderColumn>
            <TableHeaderColumn dataField="valorRecebidoFinanceiro" width='150' hidden={this.state.hiddenColumns.valorRecebidoFinanceiro} tdStyle={{ whiteSpace: 'nowrap' }} className="point" dataSort={true} editable={{ type: 'number' }}>Valor recebido financeiro</TableHeaderColumn>
            <TableHeaderColumn dataField="totalAReceber" width='150' hidden={this.state.hiddenColumns.totalAReceber} tdStyle={{ whiteSpace: 'nowrap' }} className="point" dataSort={true} editable={{ type: 'number' }}>Total a receber</TableHeaderColumn>
            <TableHeaderColumn dataField="totalAReceberPermuta" width='150' hidden={this.state.hiddenColumns.totalAReceberPermuta} tdStyle={{ whiteSpace: 'nowrap' }} className="point" dataSort={true} editable={{ type: 'number' }}>Total a receber permuta</TableHeaderColumn>
            <TableHeaderColumn dataField="totalAReceberFinanceiro" width='150' hidden={this.state.hiddenColumns.totalAReceberFinanceiro} tdStyle={{ whiteSpace: 'nowrap' }} className="point" dataSort={true} editable={{ type: 'number' }}>Total financeiro</TableHeaderColumn>
            <TableHeaderColumn dataField="totalRecebido" width='150' hidden={this.state.hiddenColumns.totalRecebido} className="point" tdStyle={{ whiteSpace: 'nowrap' }} dataSort={true} editable={{ type: 'number' }}>Total recebido</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    )
  }
}