import React, { Component } from "react";
import './Clientes.css';
import Dashboard from '../Dashboard/Dashboard.js'
import { BootstrapTable, TableHeaderColumn, InsertModalFooter } from 'react-bootstrap-table';
import APIHaut from '../../../http/hautDigital.js';
import APICep from '../../../Services/ApiCep.js';
import Swal from 'sweetalert2';
import Autocomplete from '../../../Components/Autocomplete/Autocomplete.js';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const estadoCivil = ['Solteiro(a)', 'Casado(a)', 'Viuvo(a)', 'Divorciado(a)']
const zonas = ['Sul', 'Norte', 'Oeste']

export default class Clientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      data: [],
      endereco: {},
      nome: '',
      dataSemFormato: '',
      dNascimento: '',
      profissao: '',
      eCivil: '',
      zona: '',
      bairro: '',
      numero: '',
      pessoa: {},
      suggestions: []
    }

    this.onToogle = this.onToogle.bind(this);
    this.beforeSave = this.beforeSave.bind(this);
    this.beforeSavePart2 = this.beforeSavePart2.bind(this);
    this.customProfissaoField = this.customProfissaoField.bind(this);
    this.loadProfissao = this.loadProfissao.bind(this);
    this.profissaoInputHandle = this.profissaoInputHandle.bind(this);
  }

  onToogle() {
    this.setState({ opened: !this.state.opened })
  }

  componentDidMount() {
    APIHaut.get("/clientes").then(res => {
      function tratarData(data) {
        let stringdata = data.substring(0, 10);
        let repor = stringdata.replace(/-/g, '');
        let final = repor.substring(6, 8) + '/' + repor.substring(4, 6) + '/' + repor.substring(0, 4);
        data = final;
        return data;
      }
      res.data.map(cliente => {
        let dataFormatada = tratarData(cliente.data.dataNascimento);
        if (cliente.data.id_endereco !== null) {
          APIHaut.get(`/enderecos/${cliente.data.id_endereco}`)
          .then(res => {
            this.setState({
              data: [
                ...this.state.data,
                {
                  id_pessoa: cliente.id_pessoa,
                  id: cliente.id,
                  nome: cliente.data.nome,
                  dNascimento: dataFormatada,
                  profissao: cliente.data.profissao,
                  eCivil: cliente.data.estadoCivil,
                  bairro: res.data.bairro,
                }
              ]
            });
          }).catch(erro => {
            Toast.fire({
              icon: 'error',
              title: 'Não conseguimos recuperar alguns dados. Por favor atualize a página.'
            });
          });
        } else {
          this.setState({
            data: [
              ...this.state.data,
              {
                id_pessoa: cliente.id_pessoa,
                id: cliente.id,
                nome: cliente.data.nome,
                dNascimento: dataFormatada,
                profissao: cliente.data.profissao,
                eCivil: cliente.data.estadoCivil,
              }
            ]
          });
        }

        return null
      })
    }).catch(erro => {
      Toast.fire({
        icon: 'error',
        title: 'Não conseguimos recuperar alguns dados. Por favor atualize a página.'
      });
    });
  }
  onDeleteRow(rows) {
    rows.forEach(id => {
      APIHaut.delete(`/clientes/${id}`)
        .then(res => { })
        .catch(err => {
          console.log(err);
        });
    });
  }
  afterSaveCell(row, cellName, cellValue) {
    let data = {};
    data[cellName] = cellValue;
    APIHaut.patch(`/pessoas/${row.id_pessoa}`, data)
      .then(res => { console.log("alterado com sucesso") })
      .catch(err => {
        console.log(err);
      });
  }

  beforeSave(e) {
    // buscando endereço na api viacep
    const inputNome = document.querySelector('input.editor.edit-text[placeholder=Nome]');
    const inputDNascimento = document.querySelector('input.editor.edit-date');
    const inputProfissao = document.querySelector('input.editor.edit-text[placeholder=Profissão]');
    const inputECivil = document.querySelector('select.editor.edit-select[placeholder="Estado Civil"]');
    const inputCEP = document.querySelector('input.editor.edit-text[placeholder=CEP]');
    const inputnumero = document.querySelector('input.editor.edit-number[placeholder=Numero]');
    const inputZona = document.querySelector('select.editor.edit-select[placeholder="Zona"]');

    if (inputNome === null || inputDNascimento === null || inputProfissao === null || inputnumero === null) {
      Toast.fire({
        icon: 'error',
        title: 'Verifique se não há algum campo em branco.'
      })
    }

    const nome = inputNome.value;
    const dataNascimento = inputDNascimento.value;
    const profissao = this.state.profissao;
    const estadoCivil = inputECivil.value;
    const number = inputnumero.value;
    const zona = inputZona.value;

    this.setState({ nome, dataNascimento, profissao, estadoCivil, zona });

    var cep = inputCEP.value;

    // tem que tirar o tracinho do cep
    if (cep.indexOf('-') !== -1) {
      cep = cep.replace(/-/g, '');
    }

    if (cep.length === 8) {
      APICep.SearchCep(cep, number, zona)
        .then(res => {
          this.setState({
            endereco: {
              logradouro: res.data.logradouro,
              cep: cep,
              bairro: res.data.bairro,
              cidade: res.data.localidade,
              uf: res.data.uf,
              numero: number,
              zona: zona,
            }
          });

          this.beforeSavePart2(true);
        })
        .catch(err => {
          Toast.fire({
            icon: 'error',
            title: 'Verifique se o CEP foi digitado corretamente..'
          })
          console.log(err);
          this.beforeSavePart2(false);
        });
    }
  }

  beforeSavePart2(salvouCep) {
    APIHaut.post('/clientes', {
      endereco: this.state.endereco,
      nome: this.state.nome,
      dataNascimento: this.state.dataNascimento,
      profissao: this.state.profissao,
      estadoCivil: this.state.estadoCivil,
    })
      .then(res => {
        if (res.status === 201) {
          Toast.fire({
            icon: 'success',
            title: 'Cliente cadastrado com sucesso.'
          });

          window.location.reload();
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Não foi possível completar o procedimento. Tente novamente.'
          });
        }
      })
      .catch(err => {
        Toast.fire({
          icon: 'error',
          title: 'Não foi possível completar o procedimento. Tente novamente.'
        });
      });
  }

  createCustomModalFooter = () => {
    return (
      <InsertModalFooter
        beforeSave={this.beforeSave} />
    )
  }

  profissaoInputHandle(newValue) {
    var profissao = newValue;
    
    if (profissao.length > 0) {
      var values = profissao.split(' ');
      values = values.map(value => {
        return value = value.charAt(0).toUpperCase() + value.slice(1);
      });

      profissao = values.join(' ');
      this.setState({ profissao });
    }

    return profissao;
  }

  customProfissaoField(column, attr, editorClass, ignoreEditable) {
    return(
      <>
        <Autocomplete
          className={ editorClass }
          suggestions={ this.state.suggestions }
          onChange={ this.loadProfissao }
          handleInput={ this.profissaoInputHandle }
          placeholder={attr.placeholder}
        />
        <small className="form-text text-muted">Verifique com cuidado a ortografia, espere alguns segundos para ver se surge alguma sugestão de profissão</small>
      </>
    );
  }

  loadProfissao(profissao) {
    APIHaut.get(`/profissoes/${profissao}`)
      .then(res => {
        let options = res.data.map( profissao => profissao.nome );
        this.setState({ suggestions: options });
        return options;
      });

      return [];
  }

  render() {
    const selectRow = {
      mode: 'checkbox',
    };
    
    const cellEdit = {
      mode: 'dbclick',
      blurToSave: true,
      afterSaveCell: this.afterSaveCell,
    };

    const options = {
      onDeleteRow: this.onDeleteRow,
      insertModal: this.createCustomModal,
      paginationSize: 3,
      sortIndicator: true,
      deleteText: 'Remover cliente',
      insertText: 'Adicionar cliente',
      exportCSVText: 'Exportar para Excel',
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange,
      insertModalFooter: this.createCustomModalFooter,
    };

    return (
      <div>
        <Dashboard onToogle={this.onToogle} />
        <div className={`main ${this.state.opened ? "sidebar-opened" : "sidebar-closed"}`} id="empreendimento">
          <BootstrapTable data={this.state.data}
            insertRow
            pagination
            options={options}
            striped={true}
            exportCSV
            csvFileName='Unidades-Empreendimento'
            search
            selectRow={selectRow}
            hover={true} deleteRow
            cellEdit={cellEdit}
          >
            <TableHeaderColumn dataField="id_pessoa" dataAlign="center" hidden hiddenOnInsert>ID pessoa</TableHeaderColumn>
            <TableHeaderColumn dataField="id" isKey={true} dataAlign="center" width='50' dataSort hiddenOnInsert editable={false}>ID</TableHeaderColumn>
            <TableHeaderColumn dataField="nome" width='200' dataSort editable={{ type: 'text' }}>Nome</TableHeaderColumn>
            <TableHeaderColumn dataField="dNascimento" width='150' dataAlign="center" dataSort editable={{ type: 'date' }}>Data de nascimento</TableHeaderColumn>
            <TableHeaderColumn dataField="profissao" width='150' dataSort editable={false} customInsertEditor={ { getElement: this.customProfissaoField } }>Profissão</TableHeaderColumn>
            <TableHeaderColumn dataField="eCivil" width='100' dataSort editable={{ type: 'select', options: { values: estadoCivil } }}>Estado Civil</TableHeaderColumn>
            <TableHeaderColumn dataField="cep" hidden >CEP</TableHeaderColumn>
            <TableHeaderColumn dataField="zona" hidden editable={{ type: 'select', options: { values: zonas } }}>Zona</TableHeaderColumn>
            <TableHeaderColumn dataField="bairro" width='250' dataSort hiddenOnInsert editable={false}>Bairro</TableHeaderColumn>
            <TableHeaderColumn dataField="numero" hidden editable={{ type: 'number' }}>Numero</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    )
  }
}