import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import './Vendas.css';
import Dashboard from './Dashboard/Dashboard.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/free-regular-svg-icons";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {  VictoryChart,  VictoryAxis, VictoryLine } from 'victory';
import { Chart } from "react-google-charts";
import APIHaut from '../../http/hautDigital.js';


export default class Vendas extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            id: '',
            empreendimentoArray: [],
            embaixadoresArray: [],
            valoresEmbaixadoresArray: [],
            nomeEmpreendimento: '',
            dadosEmpreendimento: {
                vendasPorMes: {},
                motivacao: {},
                canalVendas: {},
                zonas: {
                    norte: {},
                    sul: {},
                    oeste: {},
                },
                embaixadores: [],
            },
            valueJaneiro: '',
            valueFevereiro: '',
            valueMarco: '',
            valueAbril: '',
            valueMaio: '',
            valueJunho: '',
            valueJulho: '',
            valueAgosto: '',
            valueSetembro: '',
            valueOutubro: '',
            valueNovembro: '',
            valueDezembro: '',
            decisaoMorar: '',
            decisaoInvestir: '',
            }   
        
        this.onToogle = this.onToogle.bind(this);
    }
    
    onToogle() {
        this.setState({ opened: !this.state.opened });
    }
    handleChange(event) {
        let value = event.target.value;
        let val = event.target.options[event.target.selectedIndex].text;
        this.setState({id: value});
        this.setState({nomeEmpreendimento: val});
        APIHaut.get(`/empreendimentos/${value}/estat`)
        .then(res => {
            this.setState({...this.state, dadosEmpreendimento: res.data})
            this.setState({valueJaneiro: this.state.dadosEmpreendimento.vendasPorMes.janeiro})
            this.setState({valueFevereiro: this.state.dadosEmpreendimento.vendasPorMes.fevereiro})
            this.setState({valueMaio: this.state.dadosEmpreendimento.vendasPorMes.maio})
            this.setState({valueMarco: this.state.dadosEmpreendimento.vendasPorMes.marco})
            this.setState({valueAbril: this.state.dadosEmpreendimento.vendasPorMes.abril})
            this.setState({valueJunho: this.state.dadosEmpreendimento.vendasPorMes.junho})
            this.setState({valueJulho: this.state.dadosEmpreendimento.vendasPorMes.julho})
            this.setState({valueAgosto: this.state.dadosEmpreendimento.vendasPorMes.agosto})
            this.setState({valueSetembro: this.state.dadosEmpreendimento.vendasPorMes.setembro})
            this.setState({valueOutubro: this.state.dadosEmpreendimento.vendasPorMes.outubro})
            this.setState({valueNovembro: this.state.dadosEmpreendimento.vendasPorMes.novembro})
            this.setState({valueDezembro: this.state.dadosEmpreendimento.vendasPorMes.dezembro})  
            this.setState({decisaoInvestir: this.state.dadosEmpreendimento.motivacao.investir})
            this.setState({decisaoMorar: this.state.dadosEmpreendimento.motivacao.morar})
            this.setState({embaixadoresArray : this.state.dadosEmpreendimento.embaixadores.map( empresa => `${empresa.login}`),
            valoresEmbaixadoresArray : this.state.dadosEmpreendimento.embaixadores.map( empresa => `${empresa.vendas}`)})
        });    
    }
    howMuch(e){
        if(e > 999999){
            return 'Mi'
        } else if(e < 999999 && e > 999 ){
            return 'Mil'
        } else {
            return ''
        }
    }
    twoDecimals(e){
        var num = parseFloat(e)        
        if(num> 999999){
            num = (num / 1000000)
        } else if(num < 999999 && num > 1000 ) {
            num = (num / 1000)
        } else {
            return num
        }
        num = num.toFixed(1);
        return num
    }
    makeInt(e){
        var num = parseFloat(e);
        return num
    }
    componentDidMount(e){
        APIHaut.get("/empreendimentos")
        .then(res => {
          res.data.map(empreendimento => {
              this.setState({
                empreendimentoArray: [
                    ...this.state.empreendimentoArray,
                   {id: empreendimento.id,
                    nome: empreendimento.nome}
                ]                     
              });
              return null
          });  
          if(this.state.empreendimentoArray.length !== 0){
              this.setState({id: this.state.empreendimentoArray[0].id})
              this.setState({nomeEmpreendimento: this.state.empreendimentoArray[0].nome})
          }           
        APIHaut.get(`/empreendimentos/${this.state.id}/estat`)
        .then(res => {
            this.setState({...this.state, dadosEmpreendimento: res.data})
            this.setState({valueJaneiro: this.state.dadosEmpreendimento.vendasPorMes.janeiro})
            this.setState({valueFevereiro: this.state.dadosEmpreendimento.vendasPorMes.fevereiro})
            this.setState({valueMaio: this.state.dadosEmpreendimento.vendasPorMes.maio})
            this.setState({valueMarco: this.state.dadosEmpreendimento.vendasPorMes.marco})
            this.setState({valueAbril: this.state.dadosEmpreendimento.vendasPorMes.abril})
            this.setState({valueJunho: this.state.dadosEmpreendimento.vendasPorMes.junho})
            this.setState({valueJulho: this.state.dadosEmpreendimento.vendasPorMes.julho})
            this.setState({valueAgosto: this.state.dadosEmpreendimento.vendasPorMes.agosto})
            this.setState({valueSetembro: this.state.dadosEmpreendimento.vendasPorMes.setembro})
            this.setState({valueOutubro: this.state.dadosEmpreendimento.vendasPorMes.outubro})
            this.setState({valueNovembro: this.state.dadosEmpreendimento.vendasPorMes.novembro})
            this.setState({valueDezembro: this.state.dadosEmpreendimento.vendasPorMes.dezembro}) 
            this.setState({decisaoInvestir: this.state.dadosEmpreendimento.motivacao.investir})
            this.setState({decisaoMorar: this.state.dadosEmpreendimento.motivacao.morar})
            this.setState({embaixadoresArray : this.state.dadosEmpreendimento.embaixadores.map( empresa => `${empresa.login}`),
            valoresEmbaixadoresArray : this.state.dadosEmpreendimento.embaixadores.map( empresa => `${empresa.vendas}`)})
        });                
        });   
       
    }        

    render() {     
        const { empreendimentoArray } = this.state;
        let empreendimentoList = empreendimentoArray.length > 0
        && empreendimentoArray.map((item, i) => {
        return (
            <option key={i} value={item.id}>{item.nome}</option>
        )
        }, this);
        return ( 
            <div>
               <Dashboard onToogle={this.onToogle} />
               <div className={`main ${this.state.opened ? "sidebar-opened" : "sidebar-closed"}`}>                  

               <div className="w-50 float-left">

               <div className="row mr-1">
               <div className="col col-title p-10">
                        <div className="layer w-80 mB-10">
                                    <h6 className="1h-1 black">{this.state.nomeEmpreendimento} - Dashboard</h6>
                                </div>
                   </div>
               </div>
                   
               <div className="row mr-1">
                    <div className=" cardDash col">
                        <div className="layers bd bgc-white p-10">
                                <div className="layer w-100 mB-10">
                                    <h6 className="1h-1 black">{this.state.dadosEmpreendimento.countUnidades} total de unidades</h6>
                                </div>
                                <div className="layer w-100">
                                    <div className="peer">
                                    <FontAwesomeIcon icon={ faChartBar} style={{color: "Crimson"}}/>
                                    </div>
                                        <div className="peer peer-right">
                                            <span className="circleBut green">+10%</span>
                                        </div>                            
                                </div>
                        </div>
                    </div>

                    <div className="cardDash col">
                       <div className="layers bd bgc-white p-10">
                               <div className="layer w-100 mB-10">
                                   <h6 className="1h-1 black">{this.state.dadosEmpreendimento.countPermuta} unidades de permuta</h6>
                               </div>
                               <div className="layer w-100">
                                   <div className="peer">
                                   <FontAwesomeIcon icon={ faChartBar} style={{color: "DarkMagenta"}}/>
                                   </div>
                                   <div className="peer peer-right">
                                       <span className="circleBut red">-20%</span>
                                   </div>                            
                               </div>
                           </div>
                       </div>

                    </div>

                    <div className="row mr-1">
                        <div className="col col-title p-10" >
                            QUADRO GERAL
                        </div>
                   </div>

                   <div className="row mr-1">
                        <div className=" cardDash col">
                                    <div className="layers bd bgc-white p-10">
                                            <div className="layer w-100 mB-10">
                                                <h4 className="1h-1 black fs-1-2-rem">{this.twoDecimals(this.state.dadosEmpreendimento.totalVendido)} {this.howMuch(this.state.dadosEmpreendimento.totalVendido)}
                                               </h4>
                                            </div>
                                            <div className="layer w-100">   
                                            <div className="fs-0-7-rem">TOTAL DE PREÇO VENDIDO</div>
                                            </div>
                                    </div>
                                </div>

                                <div className=" cardDash col">
                                    <div className="layers bd bgc-white  p-10">
                                            <div className="layer w-100 mB-10">
                                                <h4 className="1h-1 black fs-1-2-rem">{this.twoDecimals(this.state.dadosEmpreendimento.valorMedio)} {this.howMuch(this.state.dadosEmpreendimento.valorMedio)}</h4>
                                            </div>
                                            <div className="layer w-100">   
                                            <div className="fs-0-7-rem">MÉDIA DE VALOR VENDIDO</div>
                                            </div>
                                    </div>
                                </div>

                                <div className=" cardDash col">
                                    <div className="layers bd bgc-white p-10">
                                            <div className="layer w-100 mB-10">
                                                <h4 className="1h-1 black fs-1-2-rem">{this.twoDecimals(this.state.dadosEmpreendimento.valorM2Medio)} {this.howMuch(this.state.dadosEmpreendimento.valorM2Medio)} </h4>
                                            </div>
                                            <div className="layer w-100">   
                                            <div className="fs-0-7-rem">MÉDIA DO VALOR POR M²</div>
                                            </div>
                                    </div>
                                </div>  
                                </div>


                   <div className="row mr-1">

                        <div className="cardDash col p-10">
                            <label className="black"> R$ TABELA x R$ VENDIDO</label>    
                        <Chart
                                        width={300}
                                        height={150}
                                        chartType="ColumnChart"
                                        loader={<div>Loading Chart</div>}
                                        data={[
                                            [
                                            'Embaixador',
                                            'Valor',
                                            { role: 'style' },
                                            {   sourceColumn: 0,
                                                role: 'annotation',
                                                type: 'string',
                                                calc: 'stringify',
                                            },
                                            ],
                                            ['Tabela', this.state.dadosEmpreendimento.precoTabelaTotal, '#00cca6', `${this.twoDecimals(this.state.dadosEmpreendimento.precoTabelaTotal)} ${this.howMuch(this.state.dadosEmpreendimento.precoTabelaTotal)}` ],
                                            ['Vendido', this.state.dadosEmpreendimento.totalVendido, '#00cca6', `${this.twoDecimals(this.state.dadosEmpreendimento.totalVendido)} ${this.howMuch(this.state.dadosEmpreendimento.totalVendido)}` ],
                                            ['Desconto', this.state.dadosEmpreendimento.descontoTotal, '#00cca6', `${this.twoDecimals(this.state.dadosEmpreendimento.descontoTotal)} ${this.howMuch(this.state.dadosEmpreendimento.descontoTotal)}` ],
                                        ]}
                                        options={{
                                            legend: { position: 'none' },
                                        }}
                                        />
                        </div>

                    <div className="col p-0">   

                    <div className="cardDash p-10">

                                    <div className="layer w-100 mB-10">
                                        <h6 className="1h-1 black text-center fs-1-rem">FINANCEIRO</h6>
                                    </div>

                                    <div className="layer w-50 d-inline-block mb-4">
                                        <hr className="linha-vertical" style={{position: "absolute"}}></hr>
                                            <div className="util-margin-left">
                                                <span className=".fs-1-5-rem">R$ {this.state.dadosEmpreendimento.valorRecebidoFinanceiro}</span><br></br>
                                                <span className=".fs-0-7-rem"style={{color:"grey"}}>RECEBIDOS</span>
                                            </div>
                                    </div>

                                    <div className="layer w-50 d-inline-block mb-4">
                                        <hr className="linha-vertical" style={{position: "absolute"}}></hr>
                                            <div className="util-margin-left ">
                                                <span className=".fs-1-5-rem">R$ {this.state.dadosEmpreendimento.valorAReceberFinanceiro}</span><br></br>
                                                <span className=".fs-0-7-rem" style={{color:"grey"}}>A RECEBER</span>
                                            </div>
                                    </div>

                            </div>

                            <div className="cardDash p-10">
                                    <div className="layer w-100 mB-10">
                                        <h6 className="1h-1 black util-margin-bottom2 text-center">TOTAL DE UNIDADES QUITADAS</h6>
                                        <ProgressBar animated now={this.state.dadosEmpreendimento.countUnidadesQuitadas} label={this.state.dadosEmpreendimento.countUnidadesQuitadas} max={this.state.dadosEmpreendimento.countUnidades}/>
                                        <div className="layer w-100 text-right"><span className="fs-0-8-rem" style={{color:"grey"}}>{this.state.dadosEmpreendimento.countUnidades}</span></div>
                                    </div>
                            </div>   

                            </div>  
                    
                    </div>                   
                
                </div>
               <div className="w-50 float-right">

               <div className="row">
               <div className="col col-title p-10">
                        <div className="layer mB-10 text-right">
                                     <select className="w-25 h-25 py-1 mr-1" value={this.state.id} onChange={(e) => this.handleChange(e)}>
                                        {empreendimentoList}
                                    </select>
                                    <select className="w-25 h-25 py-1">                                        
                                        <option>2020</option>
                                        <option>2019</option>
                                        <option>2018</option>
                                    </select>
                                </div>
                   </div>
               </div>

               <div className="row ml-1">         

                       <div className="cardDash col">
                       <div className="layers bd bgc-white p-10">
                               <div className="layer w-100 mB-10">
                                   <h6 className="1h-1 black">{this.state.dadosEmpreendimento.countVendas} unidades vendidas</h6>
                               </div>
                               <div className="layer w-100">
                                   <div className="peer">
                                   <FontAwesomeIcon icon={ faChartBar} style={{color: "DarkOrange"}}/>
                                   </div>
                                   <div className="peer peer-right">
                                       <span className="circleBut green">+20%</span>
                                   </div>                            
                               </div>
                           </div>
                       </div>

                       <div className="cardDash col">
                        <div className="layers bd bgc-white p-10">
                                <div className="layer w-100 mB-10">
                                    <h6 className="1h-1 black">{this.state.dadosEmpreendimento.countEstoque} unidades em estoque</h6>
                                </div>
                                <div className="layer w-100">
                                    <div className="peer">
                                    <FontAwesomeIcon icon={ faChartBar} style={{color: "Green"}}/>
                                    </div>
                                    <div className="peer peer-right">
                                        <span className="circleBut green">+20%</span>
                                    </div>                            
                                </div>
                            </div>
                            </div>                          

                    </div>
                    <div className="row ml-1">
                        <div className="col col-title p-10" >
                            VENDAS POR MÊS
                        </div>
                        </div>

                        <div className="row ml-1">
                        <div className="cardDash col"> 
                        <VictoryChart
                            width={1000}
                            height={320} >     
                        <VictoryAxis  independentAxis/>
                            <VictoryLine
                           style={{labels: { fontSize: "10px" }}, { data: { stroke: "#00c4a0" } }}                          
                            data={[ 
                            { x: "Jan", y: this.state.valueJaneiro },
                            { x: "Fev", y: this.state.valueFevereiro },
                            { x: "Mar", y: this.state.valueMarco },
                            { x: "Abr", y: this.state.valueAbril },
                            { x: "Maio", y: this.state.valueMaio },
                            { x: "Jun", y: this.state.valueJunho },
                            { x: "Jul", y: this.state.valueJulho },
                            { x: "Ago", y: this.state.valueAgosto },
                            { x: "Set", y: this.state.valueSetembro },
                            { x: "Out", y: this.state.valueOutubro },
                            { x: "Nov", y: this.state.valueNovembro },
                            { x: "Dez", y: this.state.valueDezembro },
                        ]}
                            labels={({ datum }) => datum.y + "Mi"}
                            />
                    </VictoryChart>
                   {/* <Chart
                            width={'870px'}
                            height={'388px'}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={[
                                ['x', 'dogs'],
                                ['Janeiro', 0],
                                ['Fevereiro', 10],
                                ['Março', 23],
                                ['Abril', 17],
                                ['Maio', 18],
                                ['Junho', 9],
                                ['Julho', 11],
                                ['Agosto', 27],
                                ['Setembro', 33],
                                ['Outubro', 40],
                                ['Novembro', 32],
                                ['Dezembro', 35],
                            ]}
                            options={{
                                legend: { position: 'none'},
                                hAxis: {
                                    position: 'none'
                                },
                                vAxis: {
                                position: 'none'
                                },
                            }}
                            rootProps={{ 'data-testid': '1' }}
                        />*/}
                        </div>
                   </div>
                   
                   </div>                  

               <div className="row" style={{width: '101%'}}>                  
                        <div className="col col-title p-10">
                            RESULTADOS
                        </div>
                    </div> 
                    <div className="row">
                                    
                                    <div className="cardDash col p-10">
                                            <div className="layer w-100 mB-10">
                                                <h6 className="1h-1 black text-center">DECISÃO DE COMPRA</h6>
                                                <Chart
                                                        width={'250px'}
                                                        height={'200px'}
                                                        chartType="ComboChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={[
                                                            [
                                                            'Month',
                                                            'Vendas',
                                                            'Parcelas'
                                                            ],
                                                            ['Investir', this.state.dadosEmpreendimento.motivacao.investir, 18],
                                                            ['Morar', this.state.dadosEmpreendimento.motivacao.morar, 32],
                                                        ]}
                                                        options={{     
                                                            legend: 'none',                                                     
                                                            seriesType: 'bars',
                                                            series: { 2: { type: 'line' } },
                                                            colors: ['#00cca6', '#a5a5a5'],
                                                        }}
                                                        rootProps={{ 'data-testid': '1' }}
                                                        />
                                            </div>
                                    </div>    
            
                                    <div className="cardDash col p-10">
                                            <div className="layer w-100">
                                                <h6 className="1h-1 black text-center">CANAL DE VENDAS</h6>                        
                                            </div>
                                           {/* <VictoryPie                     
                                            width={500}
                                            height={380}
                                            animate={{
                                                duration: 2000
                                                }}
                                            data={[
                                                { x: 1, y: 2, label: `VENDA DIRETA  ${3}`},
                                                { x: 2, y: 3, label: `HAUT ${14}`},
                                                { x: 3, y: 5, label: `LEAD ${7}`},
                                                { x: 4, y: 5, label: `CORRETOR ${22}`},
                                                { x: 5, y: 5, label: `PARCEIRO ${12}`},
                                            ]}
                                            colorScale={["#eaeaea", "#59c5b2", "#727272", "#b7b7b7", "#00cca6" ]}       
                                        />*/}
                                        <Chart
                                            width={250}
                                            height={200}
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                ['Categoria', 'quantidade'],
                                                ['Venda direta', this.state.dadosEmpreendimento.canalVendas.vendaDireta],
                                                ['HAUT', this.state.dadosEmpreendimento.canalVendas.haut],
                                                ['LEAD', this.state.dadosEmpreendimento.canalVendas.lead],
                                                ['CORRETOR', this.state.dadosEmpreendimento.canalVendas.vendaDireta],
                                                ['PARCEIRO', this.state.dadosEmpreendimento.canalVendas.parceiro],
                                                ['OUTROS', this.state.dadosEmpreendimento.canalVendas.null],
                                            ]}
                                            options={{
                                                legend: 'none',
                                                pieSliceText: 'label',
                                                slices: {
                                                0: { offset: 0, color: "#eaeaea" },
                                                1: { offset: 0.2, color: "#59c5b2"  },
                                                2: { offset: 0, color: "#727272"  },
                                                3: { offset: 0, color: "#b7b7b7"  },
                                                4: { offset: 0, color: "#00cca6"  },
                                                5: { offset: 0, color: "#00fcc9"  },
                                                },
                                            }}
                                            rootProps={{ 'data-testid': '5' }}
                                            />
                                    </div>   
            
                                 <div className="cardDash col pt-20" id="sectionChar">
                                            <div className="layer w-100">
                                                <h6 className="1h-1 black text-center">VENDA POR EMBAIXADOR</h6>                        
                                            </div>
                                                <Chart
                                                    width={250}
                                                    height={200}
                                                    chartType="BarChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={[
                                                        [
                                                        'Embaixador',
                                                        'Valor',
                                                        { role: 'style' },
                                                        {   sourceColumn: 0,
                                                            role: 'annotation',
                                                            type: 'string',
                                                            calc: 'stringify',
                                                        },
                                                        ],
                                                        [this.state.embaixadoresArray[0], parseInt(this.state.valoresEmbaixadoresArray[0]), '#00cca6', `${this.state.valoresEmbaixadoresArray[0]}` ],
                                                        [this.state.embaixadoresArray[1], parseInt(this.state.valoresEmbaixadoresArray[1]), '#00cca6', `${this.state.valoresEmbaixadoresArray[1]}`],
                                                        [this.state.embaixadoresArray[2], parseInt(this.state.valoresEmbaixadoresArray[2]), '#00cca6', `${this.state.valoresEmbaixadoresArray[2]}`],
                                                        [this.state.embaixadoresArray[3], parseInt(this.state.valoresEmbaixadoresArray[3]), '#00cca6', `${this.state.valoresEmbaixadoresArray[3]}` ],
                                                        [this.state.embaixadoresArray[4], parseInt(this.state.valoresEmbaixadoresArray[4]), '#00cca6', `${this.state.valoresEmbaixadoresArray[4]}` ],
                                                    ]}
                                                    options={{
                                                        width: '100%',
                                                        legend: { position: 'none' },
                                                    }}
                                                    />
                                              
                                     </div>
            
                                    <div className="cardDash col p-10">
                                            <div className="layer w-100">
                                                <h6 className="1h-1 black text-center">BAIRROS QUE MAIS COMPRARAM</h6>                                                            
                                            </div>
                                            <Chart
                                                width={300}
                                                height={180}
                                                chartType="PieChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                    ['Zona', 'Quantidade'],
                                                    ['Zona Sul', this.state.dadosEmpreendimento.zonas.sul.qtd],
                                                    ['Zona Norte', this.state.dadosEmpreendimento.zonas.norte.qtd],
                                                    ['Zona Oeste', this.state.dadosEmpreendimento.zonas.oeste.qtd],
                                                ]}
                                                options={{
                                                    is3D: true,
                                                    slices: {
                                                        0: { offset: 0, color: "#00dbab" },
                                                        1: { offset: 0, color: "#c9c9c9"  },
                                                        2: { offset: 0, color: "#727272"  },
                                                        },
                                                }}
                                                rootProps={{ 'data-testid': '2' }}
                                                />
                                           {/* <Chart
                                                width={200}
                                                height={200}
                                                chartType="TreeMap"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                    [
                                                    'Location',
                                                    'Parent',
                                                    'Quantidade (size)',
                                                    'Cor (color)',
                                                    ],
                                                    ['Zonas', null, 0, 0],
                                                    ['Zona Norte', 'Zonas', 5, 2],
                                                    ['Zona Sul', 'Zonas', 6, 12],
                                                    ['Zona Leste', 'Zonas', 7, 10],
                                                    ['Zona Oeste', 'Zonas', 1, 8],
                                                    {/*['Boa Viagem', 'Zona Sul', 2.34, 2],
                                                    ['Piedade', 'Zona Sul', 0.5, 2],
                                                    ['Jaqueira', 'Zona Norte', 2.07, 13],
                                                    ['Parnamirim', 'Zona Norte', 1.38, 31],
                                                    ['Santana', 'Zona Norte', 1.33, 20],
                                                    ['Casa Forte', 'Zona Norte', 1.08, 20],
                                                    ['Madalena', 'Zona Oeste', 0.2, 20],
                                                ['Afogados', 'Zona Oeste', 0.5, 13],
                                                ]}
                                                options={{
                                                    minColor: '#00a382',
                                                    midColor: '#adadad',
                                                    maxColor: '#00e8b9',
                                                    headerHeight: 15,
                                                    fontColor: 'black',
                                                    showScale: false,
                                                    generateTooltip: (row, size, value) => {
                                                    return (
                                                        '<div style="background:#fd9; padding:10px; border-style:solid"> ' +
                                                         size + " Mi"+
                                                        '</div>'
                                                    )
                                                    },
                                                }}
                                                rootProps={{ 'data-testid': '3' }}
                                                />*/}
                                        </div>
            
                                        <div className="cardDash col p-10">
                                            <div className="layer w-100">
                                                <h6 className="1h-1 black text-center">TOP 5 CLIENTES</h6>                        
                                            </div>
                                          
                                                <Chart
                                                    width={250}
                                                    height={200}
                                                    chartType="ColumnChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={[
                                                        [
                                                        'Cliente',
                                                        'Valor',
                                                        { role: 'style' },
                                                        {   sourceColumn: 0,
                                                            role: 'annotation',
                                                            type: 'string',
                                                            calc: 'stringify',
                                                        },
                                                        ],
                                                        ['Lúcia Duarte', 1.40, '#00cca6', '1.4 Mi' ],
                                                        ['Ana Karla', 1.0, '#00cca6', '1 Mi' ],
                                                        ['MF Artefatos', 0.98, '#00cca6', '0.98 Mi' ],
                                                        ['Jean Carlos', 0.87, '#00cca6', '0.87 Mi' ],
                                                        ['Renato de Moura', 0.80, '#00cca6', '0.80 Mi' ],
                                                    ]}
                                                    options={{
                                                        legend: { position: 'none' },
                                                        hAxis: { display: 'none' },
                                                        vAxis: { display: 'none' },
                                                    }}
                                                    
                                                    />
                                        </div>
                                       {/* <div className="cardDash col p-10">
                                            <div className="layer w-100">
                                                <h6 className="1h-1 black text-center">PAGAMENTO À VISTA</h6>                        
                                            </div>
                                            <VictoryBar
                                             alignment="middle"
                                                padding={{ left: 100}}
                                                width={200}
                                                height={130}
                                                barWidth={85}
                                                data={[{y: 20}]}
                                                labels={({ datum }) => datum.y}
                                                style={{ labels: { fill: "white" } }, {data: { fill: "#00c4a0"}} }
                                                labelComponent={<VictoryLabel dy={30}/>}
                                                />
                                                </div>*/}
            
            
                                        </div> 

     
                {/*Div para fechar main e div*/}
                </div>
            </div>
        )        
    }
}